
import {  useLocation } from 'react-router-dom';
import ContentCard from '../components/ContentCard';
import StatCard from '../components/StatCard';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Amplify, {Auth, API,graphqlOperation} from 'aws-amplify';
import awsExports from '../aws-exports';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import warningIcon from '../img/warning.png'
import up from "../img/arrow-up.png";
import down from "../img/arrow-down.png";
import utils from "../utils";
import awsConfig from '../aws-exports';
import Lambda from 'aws-sdk/clients/lambda';
Amplify.configure(awsConfig);
/**
 * @returns 
 */
 export function BuildingSummary() {

    //Class for the nav - whether it is hidden or not
    const [navClass, setNavClass] = useState('nav-hide')
    //Whether menu icon is visible or not
    const [menuIconVisible, setMenuIconVisible] = useState(true)
    //Whether close icon is visible or not
    const [closeIconVisible, setCloseIconVisible] = useState(false)

    // const blackList = ["PID_0007", "PID_0004", "PID_0008", "PID_0006", "PID_0003"]
    const blackList = [ "PID_0007", "PID_0004", "PID_0003"]
    // const blackList = ["PID_0007", "PID_0004", "PID_0008", "PID_0006"]
    const elevatorBlackList = ["4602a587-61a7-440e-b276-7d904b3407bb", "ef90ba88-e9ce-4ef9-8a88-3734d9ad0021", "3f5fbbf5-72e5-488f-87ef-d89e675e2522", "93dea42d-af21-45e7-9b6f-0a277e1b167f", "9288f99f-5aa3-4bf4-9720-24006a94bac1"]

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    let [projects, setProjects] = useState();
    const [parsedProjects, setParsedProjects] = useState();


    const [userConnected, setUserConnected] = useState([]);
    const [userGroup, setUserGroup] = useState([]);
    let [listOfProjects,setListOfProjects] = useState([]);
    let [idGuestCodes, setIdGuestCodes] = useState([]);
    const [totalElevators, setTotalElevators] = useState(0);
    const [totalActiveElevators, setTotaActiveElevators] = useState(0);
    const [totalWarnings, setTotalWarnings] = useState(0);
    const [totalAlarms, setTotalAlarms] = useState(0);

    const publicAssetsBaseUrl = "https://public-assets-tsaro.s3.amazonaws.com/"
    const totalElevatorsIcon = publicAssetsBaseUrl + 'Total-Number.svg'
    const totalActiveElevatorsIcon = publicAssetsBaseUrl + 'Active.svg'
    const totalWarningsIcon = publicAssetsBaseUrl + 'Warning.svg'
    const totalAlarmsIcon = publicAssetsBaseUrl + 'Alert.svg'

    /**
     * Get the total elevators and total active elevators - July 4th, 2023
     * @param {*} listOfProjects 
     */
    const getElevatorTotals = (listOfProjects) => {
        const listOfElevators = listOfProjects.map(project => JSON.parse(project?.projectElevatorInfo))
        let flatelevators = listOfElevators.flat()
        flatelevators = flatelevators.filter(elevator => {return !elevatorBlackList.includes(elevator?.elevatingDeviceNo)})
        // !blackList.includes(project?.projectId)
        const activeElevators = flatelevators.map(e => e.deviceStatus === 'Active')
        setTotalElevators(flatelevators.length)
        setTotaActiveElevators(activeElevators.length)
    }

    /**
     * Retrieves the data for tyhe projects based on the type of user
     * Last updated by Gord Bond, July 4th, 2023
     */
    let fetchProjects = async () => {
        try {
            const tempUserConnected = Auth?.user?.signInUserSession?.idToken?.payload?.sub
            let userData = await API.graphql(graphqlOperation(queries.getUser, { id: tempUserConnected }));
            let pref_username = userData?.data?.getUser?.preferred_username;
            let reg_code = userData?.data?.getUser?.registration_code;
            const tempUserGroup = Auth?.user?.signInUserSession?.idToken?.payload['cognito:groups']
            setUserConnected(pref_username)

            if (tempUserGroup[0]==="Admin"){
                const projectData = await API.graphql({ query: queries.listProjects });
                listOfProjects = projectData?.data?.listProjects?.items
                listOfProjects.sort((a, b) => new Date(b["projectCreationDate"]) - new Date(a["projectCreationDate"]))
                listOfProjects = listOfProjects.filter(project => {return !blackList.includes(project?.projectId)})
                // setProjects(listOfProjects.filter(project => {return !blackList.includes(project?.projectId)}))
                setProjects(listOfProjects)
                getElevatorTotals(listOfProjects) // Updated July 4th, 2023 - Gord Bond
                setUserGroup('Admin')
            }else{
                if (tempUserGroup[0]==='ElevatorCompany'){
                    let projectData = await API.graphql(graphqlOperation(queries.projectByEC,{projectEC: pref_username}));
                    listOfProjects = projectData?.data?.projectByEC?.items
                    listOfProjects.sort((a, b) => new Date(b["projectCreationDate"]) - new Date(a["projectCreationDate"]))
                    // setProjects(listOfProjects)
                    setUserGroup('ElevatorCompany')
                    let CodeData = await API.graphql(graphqlOperation(queries.guestCodeByCode,{code: reg_code}));
                    idGuestCodes = CodeData?.data?.guestCodeByCode?.items;
                    idGuestCodes.map(async(gcId, indexTest) => {
                        let info = await API.graphql(graphqlOperation(queries.getGuestCode,{id: gcId.id}));
                        let list= info.data.getGuestCode.guestCode.items;
                        let info2 = await API.graphql(graphqlOperation(queries.getProject,{id: list[0].projectID}));
                        let newObject = info2?.data?.getProject;
                        listOfProjects=[...listOfProjects,newObject];
                        setProjects(listOfProjects)
                        getElevatorTotals(listOfProjects) // Updated July 4th, 2023 - Gord Bond
                    })    
                    if(listOfProjects.length === 0)
                        setProjects([])
                } else {
                    if (tempUserGroup[0]==='BuildingManager'){
                        let projectData = await API.graphql(graphqlOperation(queries.projectByBM,{projectBM: pref_username}));
                        listOfProjects = projectData?.data?.projectByBM?.items
                        listOfProjects.sort((a, b) => new Date(b["projectCreationDate"]) - new Date(a["projectCreationDate"]))
                        // setProjects(listOfProjects)

                        setUserGroup('BuildingManager')
                        let CodeData = await API.graphql(graphqlOperation(queries.guestCodeByCode,{code: reg_code}));
                        idGuestCodes = CodeData?.data?.guestCodeByCode?.items;
                        idGuestCodes.map(async(gcId, indexTest) => {
                            let info = await API.graphql(graphqlOperation(queries.getGuestCode,{id: gcId.id}));
                            let list= info.data.getGuestCode.guestCode.items;
                            let info2 = await API.graphql(graphqlOperation(queries.getProject,{id: list[0].projectID}));
                            let newObject = info2?.data?.getProject;
                            listOfProjects=[...listOfProjects,newObject];
                            setProjects(listOfProjects)
                            getElevatorTotals(listOfProjects) // Updated July 4th, 2023 - Gord Bond
                        })
                        if(listOfProjects.length === 0)
                            setProjects([])    
                    } else {
                        if (tempUserGroup[0]==='Guest'){  
                            listOfProjects = []
                            try{     
                                let CodeData = await API.graphql(graphqlOperation(queries.guestCodeByCode,{code: reg_code}));
                                idGuestCodes = CodeData?.data?.guestCodeByCode?.items;
                                console.log("idGuestCodes",idGuestCodes,listOfProjects);
                                idGuestCodes.map(async(gcId, indexTest) => {
                                    let info = await API.graphql(graphqlOperation(queries.getGuestCode,{id: gcId.id}));
                                    let list= info.data.getGuestCode.guestCode.items;
                                    let info2 = await API.graphql(graphqlOperation(queries.getProject,{id: list[0].projectID}));
                                    let newObject = info2?.data?.getProject;
                                    listOfProjects=[...listOfProjects,newObject];
                                    setProjects(listOfProjects)
                                    getElevatorTotals(listOfProjects) // Updated July 4th, 2023 - Gord Bond
                                })    
                                setProjects([])
                                                                  
                                
                                
                                listOfProjects.sort((a, b) => new Date(b["projectCreationDate"]) - new Date(a["projectCreationDate"]))
                                setUserGroup('Guest')
                            }catch(error){
                                console.log('err',error )
                            }

                        }

                    }
                }

                }
            
            //Get critical alerts
            let alertsReturned = await API.graphql(graphqlOperation(queries.listAlerts,{filter: {
                status: {
                    contains: "CRITICAL",
                },
            }}));

            const listOfAlerts = alertsReturned?.data?.listAlerts?.items
            console.log("listOfAlerts: ", listOfAlerts)

            //Set notifications for projects with a critical alert
            listOfProjects.forEach((project,i)=>{
                if(listOfAlerts.some(alert=>alert.project_id === project.projectId)){
                    listOfProjects[i]['alert']= true
                }else{
                    listOfProjects[i]['alert']= false
                }
            })
        } catch (e) {
            console.log("Error fetching project: ", e)
        }
    }

    useEffect(() => {
        fetchProjects()
        
    }, [])



    useEffect(() => {
        console.log("projects in useEffect: ", projects)
        if (projects === undefined ) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [projects])
  
    //Handles open/close of hamburger icon
    const handleClick = () => {
      setNavClass(v => v === "nav-hide" ? "nav-show" : "nav-hide")
      setMenuIconVisible(v => !v)
      setCloseIconVisible(v => !v)
    }


  
    return (
          <>
            <div className="main-container">
              <div className="content-container">    
                <h3 className='dark-title'>BUILDINGS</h3>
                <h5 className='dark-title'>Home / Dashboards / <u>Buildings</u></h5>
                <div className="flex-container">
                    <StatCard data={totalElevators} img={totalElevatorsIcon} colour={'#3994d4'} title='Total Elevators'/>
                    <StatCard data={totalActiveElevators} img={totalActiveElevatorsIcon} colour={'#20c997'} title='Active Elevators'/>
                    <StatCard data={totalWarnings} img={totalWarningsIcon} colour={'#fd7e14'} title='Warnings'/>
                    <StatCard data={totalAlarms} img={totalAlarmsIcon} colour={'#dc3545'} title='Alerts'/>
                </div>
                <div className="flex-container">
                    {
                        projects?.map((project, index) => {
                            const projectAddress  = []
                            const stNo = project.projectOwnerStNo === ' ' ? project.projectOwnerStNo.replace(" ", "") : project.projectOwnerStNo
                            const stName = project.projectOwnerStName === ' ' ? project.projectOwnerStName.replace(" ", "") : project.projectOwnerStName
                            const stAddress = stNo + " " + stName
                            if (stAddress !== ' ') 
                                projectAddress.push(stAddress)
                            if (project.projectOwnerCity && project.projectOwnerCity !== ''){
                                projectAddress.push(project.projectOwnerCity.charAt(0).toUpperCase() + project.projectOwnerCity.slice(1))
                            }
                            if (project.projectOwnerState && project.projectOwnerState.replace(" ", "") !== '') 
                                projectAddress.push(project.projectOwnerState.charAt(0).toUpperCase() + project.projectOwnerState.slice(1))
                            if (project.projectOwnerCountry && project.projectOwnerCountry.replace(" ", "") !== '') 
                                projectAddress.push(project.projectOwnerCountry.charAt(0).toUpperCase()  + project.projectOwnerCountry.slice(1))
                            if (project.projectOwnerPostalCode && project.projectOwnerPostalCode.replace(" ", "") !== '') 
                                projectAddress.push(project.projectOwnerPostalCode)
                            const projectAddressStr = projectAddress.join(', ')

                            {/* Get elevator info */}
                            let elevatorInfo = JSON.parse(project?.projectElevatorInfo)
                            elevatorInfo = elevatorInfo.filter(elevator => {return !elevatorBlackList.includes(elevator?.elevatingDeviceNo)})
                            const elevatorActiveList = elevatorInfo.map(e => {
                                return e.deviceStatus === 'Active'
                                })
                            const totalInactive = elevatorInfo.length-elevatorActiveList.length
                            const totalActiveForElevator = elevatorActiveList.length
                            const stats = {mainStat: elevatorInfo.length, subStats:{active: totalActiveForElevator, inactive: totalInactive, caution: 0, warning: 0}}
                            return <ContentCard key={index} title={project?.projectLocationofElevatingDevice} subtitle={projectAddressStr} stats={stats} type={'Building'} summaryData={project}/>
                        })
                    }
                </div>

              </div>
            </div>
          </>
    );
    };

export default BuildingSummary;