import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Mulish-Bold.ttf';
import './fonts/OpenSans-Regular.ttf';
import './fonts/OpenSans-SemiBold.ttf';
import { ThemeProvider, Theme, useTheme} from '@aws-amplify/ui-react';


const theme = {
    
    name: 'Auth Example Theme',
    tokens: {
      colors: {
        background: {
          primary: {
            value: 'rgb(222 114 25 / 0%)',
          },
          secondary: {
            value: 'rgb(222 114 25 / 0%)',
          },
        },
        border: {
          primary: {
            value: 'rgb(222 114 25 / 0%)',
          },
          secondary: {
            value: 'rgb(222 114 25 / 0%)',
          },
          focus:{
            value: 'rgb(222 114 25 / 0%)',
          },
          pressed:{
            value: 'rgb(222 114 25 / 0%)',
          },
          active:{
            value: 'rgb(222 114 25 / 0%)',
          },
        },
        font: {
          interactive: {
            value: '#000',
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: 'rgb(250 250 250 / 100%)',
              },
            },
            _hover: {
              color: {
                value: 'rgb(250 250 250 / 100%)',
              },
            },
            _active: {
              color: {
                value: 'rgb(250 250 250 / 100%)',
              },
              borderColor: {
                value: 'rgb(250 250 250 / 80%)'
              }
            },
            color: {
              value: 'rgb(250 250 250 / 100%)',
            }
          },

        },
        authenticator: {
          router: {
            boxShadow: {
              value: 'rgb(250 250 250 / 0%)',
            }
          }
        },
        textfield: {
          borderColor: {
            value: 'rgb(250 250 250 / 100%)',
          },
          color: {
            value: 'rgb(250 250 250 / 100%)',
          }
        },
        button: {
            primary: {
                backgroundColor: { value: '#0088ff' },
            },
            _hover: { 
              backgroundColor: { value: 'rgb(222 114 25 / 0%)' },
              color: {value: "rgb(222 114 25 / 100%)"}
            },
          
        },
      },
    },
  };

const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
root.render(
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
