import Auth from '@aws-amplify/auth';
import Lambda from 'aws-sdk/clients/lambda';

//import of components from chart.js

import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineController,
    LineElement,
    LinearScale,
    PointElement,
    Tooltip,
} from 'chart.js';
// import 'jspdf-autotable';
//import of React component
import React, { useEffect, useState, useRef } from "react";

// Import for line, bar chart from react-chartjs-2
import { Line, Bar, Chart } from 'react-chartjs-2';

// Import for the zoom-plugins of chart 
import zoomPlugin from 'chartjs-plugin-zoom';

// Import for the labels of heatmap
import { AxisBottom, AxisLeft } from '@visx/axis';

// Imports for heatmap 
import { HeatmapRect } from '@visx/heatmap';
import { scaleLinear, scaleBand, scalePoint } from '@visx/scale';

//Import for exporting chart as pdf
import { jsPDF } from "jspdf";

// register all components of ChartJs
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    zoomPlugin
);
export function ReportChart() {

    const [FloorData, setFloorData] = useState([]);
    const [FloorDataTimeStamp, setFloorDataTimeStamp] = useState([]);

    const [selectedOption, setSelectedOption] = useState();
    const [rawElevatorData, setRawElevatorData] = useState();

    const [heatdata, setheatdata] = useState([]);

    // parameter useState
    const [displayparameter, setparameter] = useState("week");

    const [inParameter, setinParameter] = useState();
    // loading UseState
    const [isLoading, setisLoading] = useState(true);

    const [runsperFloor, setrunsperFloor] = useState([]);

    const [runCounts, setrunCounts] = useState();
    const [totalDistance, setTotalDistance] = useState();
    const [floorCounts, setFloorCounts] = useState();
    const [elevatorId, setelevatorId] = useState();
    const events = true;

    let count = 0;
    // declaring arrays to store the data
    let elevatorData;
    let WeekFLoorArray = [];

    let WeekTimestampArray = [];
    let tokenArray = [];
    let initialHeatData = [];

    const datacall = async (parameter, rawElevatorData, elevatorId) => {

        // console.log("id", elevatorId);
        // console.log("data", rawElevatorData.length);

        setelevatorId(elevatorId);
        // console.log(parameter);
        if (parameter === "run_counts") {
            console.log("count", rawElevatorData[0].total_count);
            setrunCounts(rawElevatorData[0].total_count);
        }
        if (parameter === "floor_counts") {
            console.log("count", rawElevatorData[0].total_floor_changes_count);
            setFloorCounts(rawElevatorData[0].total_floor_changes_count);
            setTotalDistance(rawElevatorData[0].total_distance);
        }
        if (parameter === "raw_data") {
            for (let i = 1; i < rawElevatorData.length; i++) { // start at index 1 to skip the first element as they are the labels only

                if (elevatorId === rawElevatorData[i].elevator_id) {

                    WeekFLoorArray.push(parseInt(rawElevatorData[i].from_floor));
                    WeekTimestampArray.push((new Date(rawElevatorData[i].full_date_from)).toLocaleString('en-US', { hour12: true }));

                }
            }
            // console.log("data", WeekFLoorArray.length);
            setFloorData(WeekFLoorArray);
            setFloorDataTimeStamp(WeekTimestampArray);
        }
        if (parameter === "floor_trips") {
            const floorCounts = {};

            for (const entry of rawElevatorData) {
                const today = new Date();
                const millisecondsInADay = 24 * 60 * 60 * 1000;

                // Add 1 to match Sunday (0) with 1, and take modulo 7 to handle Saturday (6)
                const daysToSubtract = (today.getDay() + 1) % 7;

                //lastweek date
                const lastWeek = new Date(today.getTime() - (daysToSubtract * millisecondsInADay));
                const from_floor_date = new Date(entry.full_date_from);
                const fromFloor = Math.floor(parseFloat(entry.from_floor));
                const toFloor = Math.floor(parseFloat(entry.to_floor));
                if (displayparameter === "week") {
                    if (from_floor_date >= lastWeek && from_floor_date <= today) {
                        if (!floorCounts[fromFloor]) {
                            floorCounts[fromFloor] = {
                                bin: fromFloor,
                                bins: []
                            };
                        }

                        // manipulating data as per the HEATMAP requirement

                        const binIndex = floorCounts[fromFloor].bins.findIndex(bin => bin.bin === toFloor);
                        if (binIndex > -1) {
                            floorCounts[fromFloor].bins[binIndex].count++;
                        } else {
                            floorCounts[fromFloor].bins.push({ bin: toFloor, count: 1 });
                        }
                    }
                } else if (displayparameter === "daily") {
                    if (from_floor_date.getDate() === today.getDate()) {
                        if (!floorCounts[fromFloor]) {
                            floorCounts[fromFloor] = {
                                bin: fromFloor,
                                bins: []
                            };
                        }

                        // manipulating data as per the HEATMAP requirement

                        const binIndex = floorCounts[fromFloor].bins.findIndex(bin => bin.bin === toFloor);
                        if (binIndex > -1) {
                            floorCounts[fromFloor].bins[binIndex].count++;
                        } else {
                            floorCounts[fromFloor].bins.push({ bin: toFloor, count: 1 });
                        }

                    }
                }

            }

            // getting 1 1 => 0 for extra pairs of data 
            for (const floorCount of Object.values(floorCounts)) {
                const bins = floorCount.bins;
                for (let i = 0; i <= 30; i++) {
                    if (!bins.find(b => b.bin === i)) {
                        bins.push({ bin: i, count: 0 });
                    }
                }
            }

            // sorting the data 
            // const floorCountList = Object.values(floorCounts);

            const floorCountList = Object.values(floorCounts);




            floorCountList.sort((a, b) => {
                if (a.bin !== b.bin) {
                    return a.bin - b.bin;
                } else {
                    return a.bins[0].bin - b.bins[0].bin;
                }
            });


            // floorCountList.pop()
            // sorting bins.bin 
            for (let i = 0; i < floorCountList.length; i++) {
                floorCountList[i].bins.sort((a, b) => b.bin - a.bin);
            }

            for (const floorCount of floorCountList) {
                const floor = floorCount.bin;
                const bins = floorCount.bins;

                const existingData = initialHeatData.find(data => data.bin === floor);
                if (existingData) {
                    for (const bin of bins) {
                        const existingBin = existingData.bins.find(b => b.bin === bin.bin);
                        if (existingBin) {
                            existingBin.count += bin.count; // Increase the count by the new count
                        } else {
                            existingData.bins.push({ bin: bin.bin, count: bin.count });
                        }
                    }
                } else {
                    initialHeatData.push({ bin: floor, bins: [...bins] });
                }
            }

            // sorting the initialHeatData by bin value
            // initialHeatData.sort((a, b) => a.bin - b.bin);

            console.log("initialHeatData", initialHeatData);
            setheatdata(initialHeatData);
            // setheatdata(floorCountList);
        }
        if (parameter === "floor") {

            // console.log("floor");
            console.log("floor_data", rawElevatorData)
            let sumsByFloor;
            const today = new Date();

            // Calculate the number of milliseconds to subtract based on the current day of the week
            const millisecondsInADay = 24 * 60 * 60 * 1000;

            // Add 1 to match Sunday (0) with 1, and take modulo 7 to handle Saturday (6)
            const daysToSubtract = (today.getDay() + 1) % 7;

            //lastweek date
            const lastWeek = new Date(today.getTime() - (daysToSubtract * millisecondsInADay));

            // console.log("displayparameter", lastWeek)
            if (displayparameter === "week") {
                sumsByFloor = rawElevatorData.reduce((acc, item) => {
                    // filtering the data with the Date Filter of week
                    const fromDate = new Date(item.full_date_from
                    );

                    // console.log("fromdate",fromDate);
                    // if (fromDate >= lastWeek && fromDate <= today) {
                    const floor = item.to_floor;
                    // console.log("floor", floor)
                    const count = parseInt(item.count);
                    // count increase as per the key of Floor.
                    acc[floor] = (acc[floor] || 0) + count;
                    // }
                    return acc;
                }, {});

            } else if (displayparameter === "daily") {
                sumsByFloor = rawElevatorData.reduce((acc, item) => {
                    // filtering the data with the Date Filter of today
                    const fromDate = new Date(item.full_date_from
                    );
                    // if (fromDate.getDate() === today.getDate()) {
                    const floor = item.to_floor;
                    const count = parseInt(item.count);
                    acc[floor] = (acc[floor] || 0) + count;
                    // }
                    return acc;
                }, {});

            }

            // Sorting of the Data by making the sumsByFloor into array of key-value pairs 
            //and then sort it and turn that back into objects again
            const sortedSumsByFloor = Object.entries(sumsByFloor)
                .sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]))
                .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

            // console.log("sotregd", sortedSumsByFloor);
            for (const [floor, count] of Object.entries(sortedSumsByFloor)) {
                // Convert floor to a string
                const floorString = parseFloat(floor).toFixed(1);

                // Check if the floor already exists in tokenArray
                if (tokenArray.hasOwnProperty(floorString)) {
                    // Floor already exists, add the count to the existing value
                    tokenArray[floorString] += count;
                } else {
                    // Floor does not exist, create a new key-value pair
                    tokenArray[floorString] = count;
                }
            }


            //  console.log("tokenarray", tokenArray);

            setrunsperFloor(tokenArray);
        }
    }
    const invokinglambda = async (parameter, nextToken, elevator_id) => {
        Auth.currentCredentials()
            .then(credentials => {

                const lambda = new Lambda({
                    credentials: Auth.essentialCredentials(credentials),
                    region: 'us-east-1'
                });

                // defining parameters to invoke function AthenaConnectionForChart-dev
                const params = {
                    FunctionName: 'AthenaConnectionForCarCharts',
                    InvocationType: 'RequestResponse',
                    Payload: JSON.stringify({ myParam: parameter, nextToken: nextToken, elevator_id: elevator_id })
                };

                // invoking lambda by passing the defined parameters
                return lambda.invoke(params, function (err, data) {
                    if (err) console.log("ERROR", err, err.stack); // an error occurred
                    else {


                        // storing the data in elevator data
                        elevatorData = JSON.parse(data.Payload);

                        // loading component settings
                        if (elevatorData === undefined) {
                            setisLoading(true)
                        } else {
                            setisLoading(false)
                        }

                        // setting the data to the component 

                        const rawElevatorData = JSON.parse(elevatorData.body.data);

                        // try {
                        //     const nextToken = elevatorData.body.nextToken;


                        //     // Recursively invoke the Lambda function if there is a nextToken
                        //     if (nextToken && count <= 4) {
                        //         // console.log("next.......")
                        //         // console.log("count", count, "paramter", parameter)
                        //         count++;
                        //         invokinglambda(parameter, nextToken, "9288f99f-5aa3-4bf4-9720-24006a94bac1");
                        //     }
                        // } catch (error) {
                        //     console.error('Error:', error);
                        //     throw error;
                        // }
                        setRawElevatorData(rawElevatorData);
                        setinParameter(parameter);
                        datacall(parameter, rawElevatorData, elevator_id);

                    }
                });
            })
    }

    useEffect(() => {

        // calling function as per the parameter
        if (displayparameter === "daily" || displayparameter === "week") {
            invokinglambda("run_counts", null, "9288f99f-5aa3-4bf4-9720-24006a94bac1").then(invokinglambda("floor_counts", null, "9288f99f-5aa3-4bf4-9720-24006a94bac1")).then(invokinglambda("raw_data", null, "9288f99f-5aa3-4bf4-9720-24006a94bac1")).then(invokinglambda("floor_trips", null, "9288f99f-5aa3-4bf4-9720-24006a94bac1")).then(invokinglambda("floor", null, "9288f99f-5aa3-4bf4-9720-24006a94bac1"));
        }

    }, [displayparameter])

    const zoomOptions = {

        zoom: {
            wheel: {
                enabled: true,
            },

            mode: 'xy',
            drag: {
                enabled: true,
                borderColor: 'rgb(54, 162, 235)',
                borderWidth: 1,
                backgroundColor: 'rgba(54, 162, 235, 0.3)'
            }
        },
        pan: {
            modifierKey: 'ctrl',
            enabled: true,
            mode: 'xy',
        }
    };
    const Floordata = {
        labels: FloorDataTimeStamp,
        datasets: [
            {
                label: elevatorId,
                data: FloorData,
                fill: true,
                borderColor: 'orange',
                tension: 0.1
            }
        ]
    };
    const Flooroptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            zoom: zoomOptions,
            legend: {
                position: 'right',
                align: 'center',
                labels: {
                    usePointStyle: true,
                    padding: 20,
                    font: {
                        weight: 'bolder',
                        size: 14
                    }
                }
            },

        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Timestamp',
                    font: {
                        weight: 'bold',
                        size: 14
                    }
                },
                ticks: {
                    maxRotation: 90,
                    minRotation: 90,
                    font: {
                        weight: 'bold',
                        size: 14
                    }
                },
                min: 0,
                max: 89
            },
            y: {
                beginAtZero: false,
                ticks: {
                    stepSize: 5,
                    font: {
                        weight: 'bold',
                        size: 10
                    }
                },
                title: {
                    display: true,
                    text: 'Floor',
                    font: {
                        weight: 'bold',
                        size: 14
                    }
                }
            }
        }
    };



    const lineChartWeekRef1 = useRef(null);
    const lineChartWeekRef2 = useRef(null);
    const xScale = scaleLinear({
        range: [33, 77.55],

    });
    const floorRundata = {
        labels: Object.keys(runsperFloor),
        datasets: [
            {
                label: elevatorId,
                data: Object.values(runsperFloor),
                backgroundColor: 'orange',
            },

        ],
    };

    const floorRunoptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                align: 'center',
                labels: {
                    usePointStyle: true,
                    padding: 20,
                    font: {
                        weight: 'bolder',
                        size: 14
                    }
                }
            },
            zoom: zoomOptions,
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Floor',
                },
            },
            y: {
                beginAtZero: false,
                ticks: {
                    stepSize: 10
                },
                title: {
                    display: true,
                    text: 'Runs',
                },
            },

        },
    };
    //y scale for the heatmap with the difference of 33.33 inbetween two coordinates
    const yScale = scaleLinear({
        range: [0, 23.33],
    });

    // getting the bins.bin value for the HEATMAP.
    const countValues = heatdata.flatMap(({ bins }) => bins.map(({ count }) => count));

    //caculating min and max value of the countvalues
    const minValue = Math.min(...countValues);
    const maxValue = Math.max(...countValues);

    // defining colorscale for HEATMAP with the range of minValue to maxValue.
    const colorScale = scaleLinear({
        range: [minValue, maxValue],
        domain: [minValue, maxValue]
    });

    // defining opacityscale for HEATMAP
    const opacityScale = scaleLinear({
        range: [0.1, 1],
        domain: [minValue, maxValue],
    });
    const handleWeekDownload = async () => {
        const listOfChartRefs = [lineChartWeekRef1, lineChartWeekRef2]
        if (listOfChartRefs) {

            const doc = new jsPDF('p', 'mm', 'a4'); // Set page size to A4

            const content = listOfChartRefs[0].current.toBase64Image();
            var currentDate = new Date();
            var day = String(currentDate.getDate()).padStart(2, "0");
            var month = String(currentDate.getMonth() + 1).padStart(2, "0");
            var year = currentDate.getFullYear();
            var hours = String(currentDate.getHours()).padStart(2, "0");
            var minutes = String(currentDate.getMinutes()).padStart(2, "0");
            var seconds = String(currentDate.getSeconds()).padStart(2, "0");

            // Create the string with the desired format
            var dateTimeString = day + "/" + month + "/" + year + " " + hours + ":" + minutes + ":" + seconds;
            doc.text(72, 7, 'Weekly Data Signal Report')
            doc.text(10, 20, 'Elevator movement');
            doc.addImage(content, "jpeg", 10, 26, 195, 95, "", 1)
            doc.text(10, 130, 'Floor Changes');
            doc.addImage(listOfChartRefs[1].current.toBase64Image(), "jpeg", 10, 136, 195, 95, "", 1)

            doc.text(doc.internal.pageSize.width - 100, doc.internal.pageSize.height - 20, "Generated on: " + dateTimeString);
            doc.text(doc.internal.pageSize.width - 105, doc.internal.pageSize.height - 10, "Powered by Tsaro and Mad Elevators");

            doc.addPage();

            doc.text(72, 7, 'Weekly Data Signal Report')
            doc.text(10, 20, 'Floor Trips');
            var canvas = document.createElement('canvas');
            canvas.width = 1460;
            canvas.height = 750;

            // Get the canvas context
            var context = canvas.getContext('2d');

            // Create an image from the SVG data
            var image = new Image();
            image.onload = function () {
                // Draw the image on the canvas
                context.drawImage(image, 0, 0);

                // Get the canvas data URL
                var dataUrl = canvas.toDataURL();

                // Remove the data URL prefix
                var base64Data = dataUrl.replace(/^data:image\/(png|jpeg);base64,/, "");

                // Add the SVG image to the PDF document
                doc.addImage(base64Data, 'PNG', 10, 26, doc.internal.pageSize.width - 40, 140);
                doc.text(doc.internal.pageSize.width - 100, doc.internal.pageSize.height - 20, "Generated on: " + dateTimeString);
                doc.text(doc.internal.pageSize.width - 105, doc.internal.pageSize.height - 10, "Powered by Tsaro and Mad Elevators");


                // Save or display the PDF document
                doc.save('weeklyReport.pdf');
            };
            image.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent('<svg xmlns="http://www.w3.org/2000/svg" width="1460" height="750">' + document.getElementById("heatmapSVG").innerHTML + '</svg>');


        }
    }
    return (
        <>
            {!isLoading ?
                <>
                    <div >
                        <div class="button-container">
                            <button
                                type="submit"
                                className="chartButton"
                                name="Sensor Signals"
                                onClick={() => { setparameter('week') }}
                            >
                                Weekly Elevator Performance
                            </button>
                            <button
                                type="submit"
                                className="chartButton"
                                name="Daily Report"
                                onClick={() => { setparameter('daily') }}
                            >
                                Daily report
                            </button>
                            <button
                                type="submit"
                                className="chartButton"
                                name="Download"
                                onClick={() => { handleWeekDownload(); }}
                            >
                                Download
                            </button>
                        </div>
                        {displayparameter === "week" || displayparameter === "daily" ?

                            <>
                                <div className="card-container">
                                    <div className="card">
                                        <h3>Total Number of runs</h3>
                                        <h2>{runCounts}</h2>
                                    </div>
                                    <div className="card">
                                        <h3>Floors Travelled</h3>
                                        <h2>{floorCounts}</h2>
                                    </div>
                                    <div className="card">
                                        <h3>Distance travelled by the elevator in m</h3>
                                        <h2>{totalDistance}</h2>
                                    </div>
                                </div>

                                <div className="chartdiv">
                                    <h3>Elevator Movement - Last 5 minutes</h3>
                                    <Chart ref={lineChartWeekRef1} type='line' data={Floordata} options={Flooroptions} />

                                </div>
                                <div className='heatmap' >
                                    <h3>Elevator Trips - From_floor to ToFloor</h3>
                                    <svg width={1460} height={750} id='heatmapSVG' >

                                        <text x={0} y={-10} transform="rotate(-90)" fontSize={12} textAnchor="middle">
                                            Floor
                                        </text>


                                        <AxisBottom
                                            scale={scaleBand({
                                                domain: Array.from({ length: 31 }, (_, i) => i.toString()),
                                                range: [25, 1420],

                                            })}
                                            top={715}
                                            tickLength={10}
                                            tickFormat={(value) => `${value}`}
                                            strokeWidth={0}
                                            numTicks={30}
                                            tickLabelProps={() => ({ fontSize: 15, textAnchor: 'middle', fontWeight: 'bold' })}

                                        />


                                        {/* Add Y-axis labels */}
                                        <AxisLeft
                                            scale={scaleBand({
                                                domain: Array.from({ length: 31 }, (_, i) => i.toString()),
                                                range: [732, 2],
                                            })}
                                            left={25}
                                            tickLength={6}
                                            tickFormat={(value) => `${value}`}
                                            strokeWidth={0}
                                            numTicks={30}
                                            tickLabelProps={() => ({ fontSize: 15, textAnchor: 'middle', dx: '-0.5em', fontWeight: 'bold', reduceXTicks: false })}
                                        />

                                        <HeatmapRect

                                            data={heatdata}
                                            xScale={xScale}
                                            yScale={yScale}
                                            colorScale={colorScale}
                                            opacityScale={opacityScale}
                                            margin={{ top: 10, bottom: 30, left: 30, right: 30 }}
                                            // Set the size of each rectangle in the heatmap
                                            events={true}
                                            gap={1}
                                        >
                                            {(heatmap) =>
                                                heatmap.map((heatmapBins) =>
                                                    heatmapBins.map((bin) => (
                                                        <rect
                                                            key={`heatmap-rect-${bin.row}-${bin.column}`}
                                                            className="visx-heatmap-rect"
                                                            width={40}
                                                            height={20}
                                                            x={bin.x}
                                                            y={bin.y}
                                                            fill={bin.color}
                                                            fillOpacity={bin.opacity}
                                                            onClick={() => {
                                                                if (!events) return;
                                                                const { row, column } = bin;
                                                                alert(JSON.stringify({ row, column, bin: bin.bin }));
                                                            }}
                                                        />
                                                    )),
                                                )
                                            }
                                        </HeatmapRect>

                                    </svg>
                                </div>
                                <div className="chartdiv">
                                    <h3>Number of Runs per Floor</h3>
                                    <Bar  ref={lineChartWeekRef2} data={floorRundata} options={floorRunoptions} />
                                </div>

                            </> : <>
                            </>
                        }
                    </div>
                </>
                :
                <legend className="caption loading-color">Loading please wait...</legend>
            }
        </>
    );
}

export default ReportChart;