import React, {
    useEffect
  } from 'react';
import { useNavigate } from "react-router-dom";

/**
 * Card component to be used for small information
 * Designed with the Car Details page in mind
 * @param {*} param0 
 * @returns 
 * Written by Gord Bond  - July 5, 2023
 */
function SmallGeneralCard({data, type, title}){

    const handleViewDetails = (e, data) => {
        console.log("Clicking on: ", data)
      }

    return (
        <div className="sm-gen-card" onClick={(e)=>{handleViewDetails(e, data)}}>
            <div className="stat-card-container">
                <div className='sm-gen-card-info'>
                    <h3 className='sm-gen-card-title'>{title}</h3>
                    { type === 'basic-car-info' ? 
                        <div className='sm-gen-text'>
                            {data?.elevatorType}
                        </div>
                        :
                        <div className='sm-gen-text'>
                            {data?.lastCheckIn}
                        </div>
                    }
                </div>


                
            </div>
        </div>
        );
}

export default SmallGeneralCard;