import React, {useEffect, useState} from 'react';
import Amplify, {Auth} from 'aws-amplify';
import AdminForm from '../components/AdminForm';
import GuestAssignmentForm from '../components/GuestAssignmentForm';
import awsExports from '../aws-exports';
import Lambda from 'aws-sdk/clients/lambda';
Amplify.configure(awsExports);
/**
 * Admin page
 * - Can send codes to users
 * @returns 
 */
export function Admin(){



    // This works... apply this to a new component. 
    let testMail = () => {
        window.open('mailto:test@example.com?subject="subject"&body="body"');
    }

    /**
     * This will return a list of devices so that we can analyze the quality of the data by device
     */
    const getListOfDevices = () => {
      Auth.currentCredentials().then(credentials => {
                const lambda = new Lambda({
                    credentials: Auth.essentialCredentials(credentials),
                    region: 'us-east-1'
                });

                // defining parameters to invoke function AthenaConnectionForChart-dev
                const params = {
                    FunctionName: 'getDeviceIds-orph',
                    InvocationType: 'RequestResponse'
                };
                return lambda.invoke(params, function (err, data) {
                  if (err) console.log("ERROR", err, err.stack); // an error occurred
                  else {
                    // storing the data in elevator data
                    const deviceIds = JSON.parse(data.Payload);

                    console.log("DEVICE IDS: ",deviceIds);
                    let csvContent = "data:text/csv;charset=utf-8," 
                      + deviceIds.map(e => e.join(",")).join("\n");
                    const encodedUri = encodeURI(csvContent);
                    window.open(encodedUri);
                  }
                })
              })
    }

    const [userGroup, setUserGroup] = useState();

    const fetchUserGroup = () => {
        try {
            const userGroupArray = Auth?.user?.signInUserSession?.idToken?.payload['cognito:groups']
            setUserGroup(userGroupArray[0])
            console.log("USER: ", Auth?.user)
            console.log("userGroup: ", userGroupArray[0])
        } catch (e) {
            console.log("Error: ", e)
            console.log("User Group with error: ",userGroup)
        }  
    };

    /**
     * Used to download list of available Device Ids so that they can be checked for data quality.
     */
    const downloadBuildingIds = () =>{
      console.log('Begin downloading device IDs...')
    }

    useEffect(() => {
      fetchUserGroup()
    },[])

    return (
          <>
            
            <div className="main-container">

              {userGroup ==='Admin' &&
                  <div className="content-container">
                    <AdminForm />
                </div>
              }
              <div className="content-container">
                <GuestAssignmentForm/>
              </div>
              {userGroup ==='Admin' &&
                <div className="content-container">
                  <div className={`form-container-sm`}>
                    <button className='main-btn' onClick={getListOfDevices}>Get Device List</button>
                  </div>
                </div>
              }
            </div>
          </>
      );
    };
export default Admin;