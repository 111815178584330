import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import awsExports from '../aws-exports';

Amplify.configure(awsExports);
/**
 * SideNav for the app. 
 * @param {*} navClass  - class for the navbar
 * @returns 
 */



export default function SideNav({ navClass, setNavClass, handleClick ,logout }) {

    let [id, setId] = useState([]);
    let [userGroup, setUserGroup] = useState([]);
    let [dropDownClass, setDropDownClass] = useState('dropdown-container hide-dropdown');
    let [showDropDown, setShowDropDown] = useState(false)
    let [dashboardBtnClass, setDashboardBtnClass] = useState('dropdown-btn');
    let [dashboardBtnActive, setDashboardBtnActive] = useState(false)
    let [caretClass, setCaretClass] = useState('fa fa-caret-left');
    let [toggleCaret, setToggleCaret] = useState(false)

    let fetchUserGroup = async () => {
        try {
            let userGroup = Auth?.user?.signInUserSession?.idToken?.payload['cognito:groups']
            setUserGroup(userGroup[0])
            let id = Auth?.user?.signInUserSession?.idToken?.payload['cognito:username']
            setId(id)
            console.log("userId: ", id)
            console.log("userGroup: ", userGroup[0])
        } catch (e) {
            console.log("Error: ", e)
        }
    }
    
    useEffect(() => {
        fetchUserGroup()
    }, [])

    const handleExpandDash = () => {
        //Toggles class to show or hide the dropdown menu
        if(showDropDown)
            setDropDownClass('dropdown-container hide-dropdown')
        else
            setDropDownClass('dropdown-container show-dropdown')
        //Toggles the active class on the dropdown/dashboard btn
        if(dashboardBtnActive)
            setDashboardBtnClass('dropdown-btn')
        else
            setDashboardBtnClass('dropdown-btn active')
        if(dashboardBtnActive)
            setCaretClass("fa fa-caret-left")
        else
            setCaretClass("fa fa-caret-down")

            
        setShowDropDown(!showDropDown)
        setToggleCaret(!toggleCaret)
        setDashboardBtnActive(!dashboardBtnActive)
    }

    // const dropdown = document.getElementsByClassName("dropdown-btn");
    // let i;

    // for (i = 0; i < dropdown.length; i++) {
    //     dropdown[i].addEventListener("click", function() {
    //         this.classList.toggle("active");
    //         var dropdownContent = this.nextElementSibling;
    //         if (dropdownContent.style.display === "block") {
    //         dropdownContent.style.display = "none";
    //         } else {
    //         dropdownContent.style.display = "block";
    //         }
    //     });
    // }

    //Click DASHBOARDS and then dropdown-container is toggled

    return (
        <nav className={navClass} role="navigation" aria-label="main navigation">
            {/* <a href="#">DASHBOARDS</a> */}
            <button className={dashboardBtnClass} onClick={()=>handleExpandDash()}>DASHBOARDS 
                <i className={caretClass}></i>
            </button>
            {userGroup=== "Admin" &&
                <div className={dropDownClass}>
                    <NavLink to="/" activeclassname='active' onClick={() => handleClick()}>Projects</NavLink>
                    <NavLink to="/iot-devices-create-and-assign-project" activeclassname='active' onClick={() => handleClick()}>Create Project</NavLink>            
                    <NavLink to="/iot-devices-project-information" activeclassname='active' onClick={() => handleClick()}>Edit Project</NavLink>
                    <NavLink to="/iot-devices-calibration-of-variables" activeclassname='active' onClick={() => handleClick()}>Calibration</NavLink>
                    <NavLink to="/admin" activeclassname='active' onClick={() => handleClick()}>Admin</NavLink> 
                </div>
            }
            { userGroup === 'BuildingManager'  &&
                <div className={dropDownClass}>
                    <NavLink to="/" activeclassname='active' onClick={() => handleClick()}>Projects</NavLink>         
                    <NavLink to="/iot-devices-project-information" activeclassname='active' onClick={() => handleClick()}>Edit Project</NavLink>
                    <NavLink to="/iot-devices-calibration-of-variables" activeclassname='active' onClick={() => handleClick()}>Calibration</NavLink>
                    <NavLink to="/admin" activeclassname='active' onClick={() => handleClick()}>Admin</NavLink> 
                </div>
            }
            { userGroup==='ElevatorCompany'  &&
                <div className={dropDownClass}>
                    <NavLink to="/" activeclassname='active' onClick={() => handleClick()}>Projects</NavLink>         
                    <NavLink to="/iot-devices-project-information" activeclassname='active' onClick={() => handleClick()}>Edit Project</NavLink>
                    <NavLink to="/iot-devices-calibration-of-variables" activeclassname='active' onClick={() => handleClick()}>Calibration</NavLink>
                    <NavLink to="/admin" activeclassname='active' onClick={() => handleClick()}>Admin</NavLink> 
                </div>
            }
            {userGroup==='Guest' &&
                <div className={dropDownClass}>
                    <NavLink to="/" activeclassname='active' onClick={() => handleClick()}>Projects</NavLink>  
                </div>
            }
            <a href="#">MY PROFILE</a>
            <a href="#">SETTINGS</a>
            <a href="#">HELP</a>
            <div className="logoFooter">
                <div className="logos-2">
                    <a href="https://tsarosolutions.com" className="website-links">
                        <img src="https://public-assets-tsaro.s3.amazonaws.com/Tsaro+Logo+White+(1).png" alt="Tsaro"/>
                    </a>
                </div>
                <div className="logos-2">
                    <a href="https://mosaicone.com" className="website-links">
                        <img src="https://public-assets-tsaro.s3.amazonaws.com/MosaicONE-+by+MAD-white.png" alt="Mad Elevators"/>
                    </a>
                </div>
            </div>
        </nav>
    )
}