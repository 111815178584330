import React, {
    useState,
    useEffect
  } from 'react';

// Stats for the car detail page that aren't charts
// Written by Gord Bond - July 25, 2023
function LargerStatCard({data, title, placeholder = false}){

    const [isLoading, setisLoading] = useState(true);

    const handleViewDetails = (e, data) => {
        console.log("Clicking on: ", data)
      }
    
      useEffect(() => {
        console.log("DATA in larger stat card: ", data)
        if(data !== null){
            setisLoading(false)
        }else{
            setisLoading(true)
        }
      },[data])


    return (
        <div className="stat-card-lg" onClick={(e)=>{handleViewDetails(e, data)}}>
            <div className="stat-card-container-lg">
                <div className='stat-card-info'>
                    <h3 className='stat-card-title-lg'>{title}</h3>
                </div>
                <div className='stat-card-info-lg'>
                    {!isLoading ? 
                        <div className={!placeholder ? 'stat-text-lg' : 'greyed-out stat-text-lg'} >{data}</div>
                    :
                        <div className="loader-container-sm">
                            {/* See classes in App.css for source */}
                            <div className="spinner"></div>
                        </div>
                    }
                </div>
            </div>
        </div>
        );
}

export default LargerStatCard;