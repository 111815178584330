import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ContentCard from "../components/ContentCard";
import SmallGeneralCard from "../components/SmallGeneralCard";
import StatCard from "../components/StatCard";
import LargerStatCard from "../components/LargerStatCard";
import ChartCard from "../components/ChartCard";
import * as lzstring from "lz-string";

import Amplify, { Auth, API, graphqlOperation } from "aws-amplify";
import Lambda from "aws-sdk/clients/lambda";
import S3 from "aws-sdk/clients/s3";
import awsConfig from "../aws-exports";
Amplify.configure(awsConfig);
const AWS = require("aws-sdk");
Auth.currentCredentials().then((credentials) => {
  AWS.config.update(credentials);
});

const pako = require("pako");
/**
 * @returns
 */
export function CarDetails() {
  const location = useLocation();
  const [car, setCar] = useState(JSON.parse(location.state)[0]);
  const [elevators, setElevators] = useState([]);
  const [userConnected, setUserConnected] = useState([]);
  const [userGroup, setUserGroup] = useState([]);
  const [totalElevators, setTotalElevators] = useState(0);
  const [totalActiveElevators, setTotaActiveElevators] = useState(0);
  const [totalWarnings, setTotalWarnings] = useState(0);
  const [totalAlarms, setTotalAlarms] = useState(0);
  const [totalCallbacks, setTotalCallbacks] = useState(0);
  const [totalUptime, setTotalUptime] = useState(100);
  const [isLoading, setisLoading] = useState(true);
  const [Var1Data, setVar1Data] = useState([]);
  const [Var2Data, setVar2Data] = useState([]);
  const [DoorStateData, setDoorStateData] = useState([]);
  const [DoorStateTimeStamp, setDoorStateTimeStamp] = useState([]);
  const [XData, setXData] = useState([]);
  const [XTimeStamp, setXtimeStamp] = useState([]);
  const [YData, setYData] = useState([]);
  const [YTimeStamp, setYtimeStamp] = useState([]);
  const [ZData, setZData] = useState([]);
  const [ZTimeStamp, setZtimeStamp] = useState([]);
  const [FloorData, setFloorData] = useState([]);
  const [FloorDataTimeStamp, setFloorDataTimeStamp] = useState([]);
  const [heatdata, setheatdata] = useState([]);
  const [heatdataWeek, setheatdataWeek] = useState([]);
  const [displayparameter, setparameter] = useState("");
  const [elevatorId, setelevatorId] = useState();
  const [runsperFloor, setrunsperFloor] = useState([]);
  const [runsperFloorWeek, setrunsperFloorWeek] = useState([]);
  const [trips, setTrips] = useState(0);
  const [dailyTrips, setDailyTrips] = useState(0);
  const [weeklyTrips, setWeeklyTrips] = useState(0);
  const [dailyFloors, setDailyFloors] = useState(0);
  const [weeklyFloors, setWeeklyFloors] = useState(0);
  const [dailyDistance, setDailyDistance] = useState(0);
  const [weeklyDistance, setWeeklyDistance] = useState(0);
  const [floors, setFloors] = useState(0);
  const [distance, setDistance] = useState(0);
  const [noCurrentData, setNoCurrentData] = useState(false);
  const [decompressedRunData, setdecompressedRunData] = useState();
  const [decompressedFLoorDayData, setdecompressedFloorDayData] = useState();
  const [decompressedTripsDayData, setdecompressedTripsDayData] = useState();
  const [decompressedTripsWeekData, setdecompressedTripsWeekData] = useState();
  // const [rawElevatorData, setRawElevatorData] = useState();
  const publicAssetsBaseUrl = "https://public-assets-tsaro.s3.amazonaws.com/";
  const totalElevatorsIcon = publicAssetsBaseUrl + "Total-Number.svg";
  const totalActiveElevatorsIcon = publicAssetsBaseUrl + "Active.svg";
  const totalWarningsIcon = publicAssetsBaseUrl + "Warning.svg";
  const totalAlarmsIcon = publicAssetsBaseUrl + "Alert.svg";
  const totalUptimeIcon = publicAssetsBaseUrl + "Green-UP.svg";
  const [lookUpFloors, setLookUpFloors] = useState();
  const [hasRetrievedDailyData, setHasRetrievedDailyData] = useState(false);
  const [hasRetrievedWeeklyData, setHasRetrievedWeeklyData] = useState(false);
  const [signalLogText, setSignalLogText] = useState("Get Signal Log");
  const [signalLogClass, setSignalLogClass] = useState("secondary-btn-outline");

  const TEN_MINUTES_IN_SECONDS = 0 * 60 * 1000;

  let tokenArray = [];

  const invokeGetCountsLambda = (buildingId, elevator_id, queryType) => {
    Auth.currentCredentials().then((credentials) => {
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: "us-east-1",
      });

      // defining parameters to invoke function AthenaConnectionForChart-dev
      const params = {
        FunctionName: "updateStatsInElevatorCache",
        InvocationType: "RequestResponse",
        Payload: JSON.stringify({
          buildingId: buildingId,
          deviceId: elevator_id,
          queryType: queryType,
        }),
      };

      // invoking lambda by passing the defined parameters
      return lambda.invoke(params, function (err, data) {
        if (err) console.log("ERROR", err, err.stack); // an error occurred
        else {
          const payload = JSON.parse(data?.Payload);
          const res = JSON.parse(payload?.body?.data);
          if (res) {
            if (queryType === "countRunsDay" || queryType === "countRunsWeek") {
              setTrips(parseInt(res));
              if (queryType === "countRunsDay") {
                setDailyTrips(parseInt(res));
              }
              if (queryType === "countRunsWeek") {
                setWeeklyTrips(parseInt(res));
              }
            } else if (
              queryType === "floorsDay" ||
              queryType === "floorsWeek"
            ) {
              setFloors(parseInt(res));
              if (queryType === "floorsDay") {
                setDailyFloors(parseInt(res));
              }
              if (queryType === "floorsWeek") {
                setWeeklyFloors(parseInt(res));
              }
            } else if (
              queryType === "distanceDay" ||
              queryType === "distanceWeek"
            ) {
              setDistance(parseInt(res)); // DOES THIS NEED TO BE CONVERTED TO FEET? -YES!
              if (queryType === "distanceDay") {
                setDailyDistance(parseInt(res));
              }
              if (queryType === "distanceWeek") {
                setWeeklyDistance(parseInt(res));
              }
            }
          } else {
            setTrips(-1);
            setDailyTrips(-1);
            setWeeklyTrips(-1);
            setDailyFloors(-1);
            setWeeklyFloors(-1);
            setDailyDistance(-1);
            setWeeklyDistance(-1);
            setFloors(-1);
            setDistance(-1); // DOES THIS NEED TO BE CONVERTED TO FEET? -YES!
          }
        }
      });
    });
  };

  const datacall = async (parameter, rawElevatorData, elevator_id) => {
    let WeekFLoorArray = [];
    let WeekTimestampArray = [];
    if (rawElevatorData.length === 0) {
      setNoCurrentData(true);
    }
    if (parameter === "rawData") {
      for (let i = 1; i < rawElevatorData.length; i++) {
        if (elevator_id === rawElevatorData[i].elevator_id) {
          WeekFLoorArray.push(parseInt(rawElevatorData[i].from_floor));

          WeekTimestampArray.push(
            new Date(rawElevatorData[i].full_date_from).toISOString()
          );
        }
      }
      setFloorData(WeekFLoorArray);
      setFloorDataTimeStamp(WeekTimestampArray);
    }
    if (parameter === "floorTripsDay" || parameter === "floorTripsWeek") {
      const floorCounts = {};
      let highestFloor = 0;
      let lowestFloor = 1000000000;
      for (const entry of rawElevatorData) {
        const fromFloor = Math.floor(parseFloat(entry.from_floor));
        const toFloor = Math.floor(parseFloat(entry.to_floor));
        const count = parseInt(entry.count);
        if (!floorCounts[fromFloor]) {
          floorCounts[fromFloor] = {
            bin: fromFloor,
            bins: [],
          };
        }

        // manipulating data as per the HEATMAP requirement
        // Instead of incrementing count use the count from the entry object
        //UPDATED BY GORD, Aug 28, 2023
        const binIndex = floorCounts[fromFloor].bins.findIndex(
          (bin) => bin.bin === toFloor
        );
        if (binIndex > -1) {
          floorCounts[fromFloor].bins[binIndex].count =
            floorCounts[fromFloor].bins[binIndex].count + count;
        } else {
          floorCounts[fromFloor].bins.push({ bin: toFloor, count: count });
        }
      }

      for (const floorCount of Object.values(floorCounts)) {
        if (floorCount.bin > highestFloor) highestFloor = floorCount.bin;
        if (floorCount.bin < lowestFloor) lowestFloor = floorCount.bin;
      }
      // getting 1 1 => 0 for extra pairs of data
      for (const floorCount of Object.values(floorCounts)) {
        const bins = floorCount.bins;
        for (let i = lowestFloor; i <= highestFloor; i++) {
          if (!bins.find((b) => b.bin === i)) {
            bins.push({ bin: i, count: 0 });
          }
        }
      }

      const floorCountList = Object.values(floorCounts);

      floorCountList.sort((a, b) => {
        if (a.bin !== b.bin) {
          return a.bin - b.bin;
        } else {
          return a.bins[0].bin - b.bins[0].bin;
        }
      });

      // sorting bins.bin
      for (let i = 0; i < floorCountList.length; i++) {
        floorCountList[i].bins.sort((a, b) => b.bin - a.bin);
      }
      parameter === "floorTripsDay"
        ? setheatdata(floorCountList)
        : setheatdataWeek(floorCountList);
    }
    if (parameter === "floorDay" || parameter === "floorWeek") {
      let tokenArray = [];
      let sumsByFloor = [];
      sumsByFloor = rawElevatorData.reduce((acc, item) => {
        // filtering the data with the Date Filter of week
        const floor = item.to_floor;
        const count = parseInt(item.count);
        // count increase as per the key of Floor.
        acc[floor] = (acc[floor] || 0) + count;
        return acc;
      }, {});

      // Sorting of the Data by making the sumsByFloor into array of key-value pairs
      //and then sort it and turn that back into objects again
      const sortedSumsByFloor = Object.entries(sumsByFloor)
        .sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

      for (const [floor, count] of Object.entries(sortedSumsByFloor)) {
        // Convert floor to a string
        const floorString = parseFloat(floor).toFixed(1);

        // Check if the floor already exists in tokenArray
        if (tokenArray.hasOwnProperty(floorString)) {
          // Floor already exists, add the count to the existing value
          tokenArray[floorString] += count;
        } else {
          // Floor does not exist, create a new key-value pair
          tokenArray[floorString] = count;
        }
      }

      parameter === "floorDay"
        ? setrunsperFloor(tokenArray)
        : setrunsperFloorWeek(tokenArray);
    }
  };

  // useEffect(() => {
  //   console.log("runsperFloor: ", runsperFloor);
  // }, [runsperFloor]);
  // useEffect(() => {
  //   console.log("var_1: ", Var1Data);
  // }, [Var1Data]);

  const datalegacycall = async (parameter, rawElevatorData, elevator_id) => {
    if (parameter === "rawData" || parameter === "rawDataWeek") {
      let Var1valuesArray = rawElevatorData?.var_1_value;
      let Var2valuesArray = rawElevatorData?.var_1_timestamp;
      let doorstateValue = rawElevatorData?.door_state_value;
      let doorStateTimestamp = rawElevatorData?.door_state_timestamp;
      let xTimestamp = rawElevatorData?.accel_x_axis_g_timestamp;
      let yTimestamp = rawElevatorData?.accel_y_axis_g_timestamp;
      let zTimestamp = rawElevatorData?.accel_z_axis_g_timestamp;

      let xValue = rawElevatorData?.accel_x_axis_g_value;
      let yValue = rawElevatorData?.accel_y_axis_g_value;
      let zValue = rawElevatorData?.accel_z_axis_g_value;
      setVar1Data(Var1valuesArray);
      setVar2Data(Var2valuesArray);
      const latestTS = new Date(Var2valuesArray[0]).toISOString();
      console.log("VAR 2 TIMESTAMP: ", latestTS);
      setFloorDataTimeStamp([latestTS]);

      setDoorStateTimeStamp(doorStateTimestamp);
      setDoorStateData(doorstateValue);

      setXtimeStamp(xTimestamp);
      setXData(xValue);

      setYData(yValue);
      setYtimeStamp(yTimestamp);

      setZData(zValue);
      setZtimeStamp(zTimestamp);
    }
    if (parameter === "floorDay" || parameter === "floorWeek") {
      // TODO: MOVE TO LAMBDA
      let sumsByFloor = [];
      sumsByFloor = rawElevatorData.reduce((acc, item) => {
        // filtering the data with the Date Filter of week
        const floor = parseInt(item.to_floor);
        const count = parseInt(item.count);
        // count increase as per the key of Floor.
        acc[floor] = (acc[floor] || 0) + count;
        return acc;
      }, {});

      // Sorting of the Data by making the sumsByFloor into array of key-value pairs
      //and then sort it and turn that back into objects again
      const sortedSumsByFloor = Object.entries(sumsByFloor)
        .sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
      console.log("sortedSumsByFloor: ", sortedSumsByFloor);
      for (const [floor, count] of Object.entries(sortedSumsByFloor)) {
        // Convert floor to a string
        const floorString = parseFloat(floor).toFixed(1);

        // Check if the floor already exists in tokenArray
        if (tokenArray.hasOwnProperty(floorString)) {
          // Floor already exists, add the count to the existing value
          tokenArray[floorString] += count;
        } else {
          // Floor does not exist, create a new key-value pair
          tokenArray[floorString] = count;
        }
      }
      console.log("tokenArray: ", tokenArray);
      setrunsperFloor(tokenArray);
    }
  };

  const invokinglambda = async (
    parameter,
    nextToken,
    buildingId,
    elevator_id
  ) => {
    console.log("Calling invokinglambda...");
    Auth.currentCredentials().then((credentials) => {
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: "us-east-1",
      });
      const s3 = new S3({
        credentials: Auth.essentialCredentials(credentials),
        region: "us-east-1",
      }); // Assigning Cred for s3. - Pal, August 22.

      // defining parameters to invoke function AthenaConnectionForChart-dev
      const params = {
        FunctionName: "CacheS3data",
        // AthenaConnectionForCarCharts
        InvocationType: "RequestResponse",
        Payload: JSON.stringify({
          myParam: parameter,
          nextToken: nextToken,
          buildingId: buildingId,
          elevator_id: elevator_id,
        }),
      };
      // invoking lambda by passing the defined parameters
      return lambda.invoke(params, function (err, data) {
        if (err) console.log("ERROR", err, err.stack); // an error occurred
        else {
          // storing the data in elevator data
          let elevatorData = JSON.parse(data?.Payload);
          // loading component settings
          if (elevatorData !== undefined) {
            // if (parameter === "floorWeek" || parameter === "floorTripsWeek") {

            try {
              // fetching the data from s3.
              async function getObjectFromS3(key) {
                if (key && key !== "") {
                  const s3Params = {
                    Bucket: "cachedatastorage",
                    Key: key,
                    ResponseCacheControl: "max-age=0",
                  };
                  try {
                    const s3Object = await s3.getObject(s3Params).promise();
                    return s3Object; // Returns the promise result containing the compressed data
                  } catch (error) {
                    console.error("Error in s3 get:", error);
                    throw error;
                  }
                }
              }

              (async () => {
                try {
                  //decompressing the data retrived from the S3
                  if (parameter !== "rawData") {
                    const s3ObjectPromiseResult = await getObjectFromS3(
                      elevatorData?.body?.data
                    );
                    datacall(
                      parameter,
                      JSON.parse(
                        pako.inflate(s3ObjectPromiseResult.Body, {
                          to: "string",
                        })
                      ),
                      elevator_id
                    );
                  } else {
                    datacall(
                      parameter,
                      JSON.parse(
                        lzstring.decompressFromEncodedURIComponent(
                          elevatorData?.body?.data
                        )
                      ),
                      elevator_id
                    );
                  }
                } catch (error) {
                  console.error("Error decompressing:", error);
                }
              })();
            } catch (error) {
              console.error("Error:", error);
            }
          }
        }
      });
    });
  };

  const invokingLegacylambda = async (
    parameter,
    nextToken,
    buildingId,
    elevator_id
  ) => {
    try {
      const credentials = await Auth.currentCredentials();
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: "us-east-1",
      });
      console.log("BUILDING ID: ", buildingId);
      const payload = JSON.stringify({
        myParam: parameter,
        nextToken: nextToken,
        buildingId: buildingId,
        elevator_id: elevator_id,
      });
      const params = {
        FunctionName: "LegacyCarDataV18",
        InvocationType: "RequestResponse",
        Payload: payload,
      };
      console.log("Payload FOR LEGACY CALL: ", JSON.parse(payload));
      // invoking lambda by passing the defined parameters
      const data = await lambda.invoke(params).promise();
      let elevatorData = JSON.parse(data?.Payload);
      if (parameter === "rawDataWeek") {
        console.log("elevatorData: ", elevatorData);
      }
      // loading component settings
      if (elevatorData?.body?.data !== undefined) {
        const rawElevatorData = JSON.parse(elevatorData?.body?.data);
        // console.log("rawElevatorData: ", rawElevatorData);
        datalegacycall(parameter, rawElevatorData, elevator_id);
      }
    } catch (err) {
      console.log("ERROR", err, err.stack); // an error occurred
    }
  };
  const processLegacyData = async (parameter, rawElevatorData) => {
    if (parameter === "rawData" || parameter === "rawDataWeek") {
      let Var1valuesArray = rawElevatorData?.var_1_value;
      let Var2valuesArray = rawElevatorData?.var_1_timestamp;
      let doorstateValue = rawElevatorData?.door_state_value;
      let doorStateTimestamp = rawElevatorData?.door_state_timestamp;
      let xTimestamp = rawElevatorData?.accel_x_axis_g_timestamp;
      let yTimestamp = rawElevatorData?.accel_y_axis_g_timestamp;
      let zTimestamp = rawElevatorData?.accel_z_axis_g_timestamp;

      let xValue = rawElevatorData?.accel_x_axis_g_value;
      let yValue = rawElevatorData?.accel_y_axis_g_value;
      let zValue = rawElevatorData?.accel_z_axis_g_value;
      const latestTS = new Date(Var2valuesArray[0]).toISOString();
      return {
        var1Data: Var1valuesArray,
        var2Data: Var2valuesArray,
        floorDataTimeStamp: [latestTS],
        doorStateTimeStamp: doorStateTimestamp,
        doorStateData: doorstateValue,
        xTimeStamp: xTimestamp,
        xData: xValue,
        yData: yValue,
        yTimeStamp: yTimestamp,
        zData: zValue,
        zTimeStamp: zTimestamp,
      };
    }
    if (parameter === "floorDay" || parameter === "floorWeek") {
      let tokenObject = {};
      let sumsByFloor = [];
      sumsByFloor = rawElevatorData.reduce((acc, item) => {
        // filtering the data with the Date Filter of week
        const floor = item.to_floor;
        const count = parseInt(item.count);
        // count increase as per the key of Floor.
        acc[floor] = (acc[floor] || 0) + count;
        return acc;
      }, {});

      // Sorting of the Data by making the sumsByFloor into array of key-value pairs
      //and then sort it and turn that back into objects again

      const sortedSumsByFloor = Object.entries(sumsByFloor)
        .sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
      for (const [floor, count] of Object.entries(sortedSumsByFloor)) {
        // Convert floor to a string
        const floorString = parseFloat(floor).toFixed(1);

        // Check if the floor already exists in tokenArray
        if (tokenObject.hasOwnProperty(floorString)) {
          // Floor already exists, add the count to the existing value
          tokenObject[floorString] += count;
        } else {
          // Floor does not exist, create a new key-value pair
          tokenObject[floorString] = count;
        }
      }
      console.log("DEBUGGING FLOOR DAY OBJECT: ", tokenObject);
      return tokenObject;
    }
  };
  /**
   * getLegacyData
   * Returns raw legacy data from the lambda function
   */
  const getLegacyData = async (
    parameter,
    nextToken,
    buildingId,
    elevator_id
  ) => {
    console.log("Calling getLegacyData... ", parameter);
    console.log("dashboard type:", car.dashboardType);
    if (car.dashboardType === "legacy") {
      try {
        const credentials = await Auth.currentCredentials();
        const lambda = new Lambda({
          credentials: Auth.essentialCredentials(credentials),
          region: "us-east-1",
        });
        const payload = JSON.stringify({
          myParam: parameter,
          nextToken: nextToken,
          buildingId: buildingId,
          elevator_id: elevator_id,
        });
        console.log("Payload FOR LEGACY CALL: ", JSON.parse(payload));
        const params = {
          FunctionName: "LegacyCarDataV18",
          InvocationType: "RequestResponse",
          Payload: payload,
        };
        const data = await lambda.invoke(params).promise();
        let elevatorData = JSON.parse(data?.Payload);
        if (parameter === "floorDay") {
          console.log("elevatorData floorDay: ", elevatorData);
        }
        // loading component settings
        // console.log("elevatorData: ", elevatorData);
        if (elevatorData?.body?.data !== undefined) {
          const rawElevatorData = JSON.parse(elevatorData?.body?.data);
          const res = processLegacyData(parameter, rawElevatorData);
          if (parameter === "floorDay") {
            console.log("res: ", res);
          }
          return res;
        } else {
          console.log("Elevator data is undefined");
          return null;
        }
      } catch (err) {
        console.log("ERROR", err, err.stack); // an error occurred
      }
    }
  };

  const {
    data: {
      var1Data,
      var2Data,
      floorDataTimeStamp,
      doorStateTimeStamp,
      doorStateData,
      xTimeStamp,
      xData,
      yData,
      yTimeStamp,
      zData,
      zTimeStamp,
    } = {},
    isLoading: legacyRawDataLoading,
    isError,
  } = useQuery({
    queryKey: ["legacyRawData", "daily"],
    queryFn: () => {
      console.log("Calling DAILY raw data in useQuery...");
      return getLegacyData(
        "rawData",
        null,
        car?.projectId,
        car?.elevatingDeviceNo
      );
    },
    enabled:
      !!car &&
      car?.dashboardType === "legacy" &&
      (displayparameter === "daily" || displayparameter === ""),
    cacheTime: 1000 * 60 * 60 * 24,
  });
  // console.log("var1Data: ", var1Data);

  const {
    data: {
      var1Data: var1DataWeek = [],
      var2Data: var2DataWeek = [],
      floorDataTimeStamp: floorDataTimeStampWeek = [],
      doorStateTimeStamp: doorStateTimeStampWeek = [],
      doorStateData: doorStateDataWeek = [],
      xTimeStamp: xTimeStampWeek = [],
      xData: xDataWeek = [],
      yData: yDataWeek = [],
      yTimeStamp: yTimeStampWeek = [],
      zData: zDataWeek = [],
      zTimeStamp: zTimeStampWeek = [],
    } = {},
    isLoading: legacyRawDataWeekLoading,
    // isError: legacyRawDataWeekError,
  } = useQuery({
    queryKey: ["legacyRawData", "weekly"],
    queryFn: () => {
      return getLegacyData(
        "rawDataWeek",
        null,
        car?.projectId,
        car?.elevatingDeviceNo
      );
    },
    enabled: !!car && displayparameter === "week",
    cacheTime: 1000 * 60 * 60 * 24,
  });
  // console.log("var1DataWeek: ", var1DataWeek);

  const {
    data: runsPerFloor,
    isLoading: isRunsPerFloorLoading,
    // isError: isRunsPerFloorError,
  } = useQuery({
    queryKey: ["runsPerFloor"],
    queryFn: () => {
      return getLegacyData(
        "floorDay",
        null,
        car?.projectId,
        car?.elevatingDeviceNo
      );
    },
    enabled: !!car,
  });
  // console.log("runsPerFloor: ", runsPerFloor);

  if (legacyRawDataLoading) {
    console.log("legacyRawDataLoading...");
  }

  if (isError) {
    console.log("isError...", isError);
  }

  /**
   * Get the total elevators and total active elevators - July 4th, 2023
   * TODO: Get the most recent floor for the card via lambda call - July 4th, 2023
   * @param {*} listOfProjects
   */
  const getElevatorTotals = (listOfElevators) => {
    setTotalElevators(listOfElevators?.length);
    const activeElevators = listOfElevators.filter(
      (e) => e?.deviceStatus === "Active"
    );
    setTotaActiveElevators(activeElevators?.length);
  };

  const handleClick = (e, param) => {
    if (
      param === "daily" &&
      trips !== null &&
      floors !== null &&
      distance !== null
    ) {
      setparameter("daily");
    } else if (
      param === "week" &&
      trips !== null &&
      floors !== null &&
      distance !== null
    ) {
      setparameter("week");
    }
  };

  const getFloors = async (deviceId) => {
    Auth.currentCredentials().then((credentials) => {
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: "us-east-1",
      });
      // defining parameters to invoke function AthenaConnectionForChart-dev
      const params = {
        FunctionName: "getFloors",
        InvocationType: "RequestResponse",
        Payload: JSON.stringify({ deviceId: deviceId }),
      };
      // invoking lambda by passing the defined parameters
      return lambda.invoke(params, function (err, data) {
        if (err) console.log("ERROR", err, err.stack); // an error occurred
        else {
          // Retrieve floors
          // const floorData = JSON.parse(data?.Payload);
          const floorData = data?.Payload;
          if (floorData !== undefined) {
            const parsedFloorData = JSON.parse(floorData);
            console.log("parsedFloorData from look up: ", parsedFloorData);
            setLookUpFloors(parsedFloorData);
          }
        }
      });
    });
  };

  useEffect(() => {
    Auth.currentCredentials().then((credentials) => {
      AWS.config.update(credentials);

      getFloors(car?.elevatingDeviceNo);

      const s3 = new S3({
        credentials: Auth.essentialCredentials(credentials),
        region: "us-east-1",
      }); // Assigning Cred for s3. - Pal, August 22.

      setelevatorId(car.elevatingDeviceNo);
      setTrips(car?.trips);
      setDailyTrips(car?.trips);
      setDailyFloors(car?.floors);
      setDailyDistance(car?.distance);
      setFloors(parseInt(car?.floors));
      setDistance(parseInt(car?.distance));
      if (
        car.elevatingDeviceNo !== "EID_0003" &&
        car.dashboardType !== "legacy"
      ) {
        if (
          car?.floorData !== "" &&
          car?.tripsData !== "" &&
          car?.rawData !== ""
        ) {
          datacall(
            "rawData",
            JSON.parse(
              lzstring.decompressFromEncodedURIComponent(car?.rawData)
            ),
            car?.elevatingDeviceNo
          );
          try {
            // fetching the data from s3.
            async function getObjectFromS3(key) {
              if (key && key !== "") {
                const s3Params = {
                  Bucket: "cachedatastorage",
                  Key: key,
                  ResponseCacheControl: "max-age=0",
                };
                try {
                  const s3Object = await s3.getObject(s3Params).promise();
                  return s3Object; // Returns the promise result containing the compressed data
                } catch (error) {
                  console.error("Error:", error);
                  throw error;
                }
              }
            }

            (async () => {
              try {
                // Decompressing the CACHED data retrived from the S3
                // -------------- WEEKLY DATA ------------------------------
                // WEEKLY FLOOR DATA
                const s3ObjectPromiseResult = await getObjectFromS3(
                  car?.floorWeekData
                );
                setdecompressedRunData(
                  pako.inflate(s3ObjectPromiseResult.Body, { to: "string" })
                );
                // WEEKLY TRIPS DATA
                const s3ObjectPromiseTripsWeekResult = await getObjectFromS3(
                  car?.tripsWeekData
                );
                setdecompressedTripsWeekData(
                  pako.inflate(s3ObjectPromiseTripsWeekResult.Body, {
                    to: "string",
                  })
                );

                // -------------- DAILY DATA ------------------------------
                // DAILY FLOORS
                const s3ObjectPromiseFloorDayResult = await getObjectFromS3(
                  car?.floorData
                );
                setdecompressedFloorDayData(
                  pako.inflate(s3ObjectPromiseFloorDayResult.Body, {
                    to: "string",
                  })
                );
                //DAILY TRIPS
                const s3ObjectPromiseTripsDayResult = await getObjectFromS3(
                  car?.tripsData
                );
                setdecompressedTripsDayData(
                  pako.inflate(s3ObjectPromiseTripsDayResult.Body, {
                    to: "string",
                  })
                );

                // SETTING DATA
                datacall(
                  "floorTripsDay",
                  JSON.parse(
                    pako.inflate(s3ObjectPromiseTripsDayResult.Body, {
                      to: "string",
                    })
                  ),
                  car?.elevatingDeviceNo
                );
                datacall(
                  "floorDay",
                  JSON.parse(
                    pako.inflate(s3ObjectPromiseFloorDayResult.Body, {
                      to: "string",
                    })
                  ),
                  car?.elevatingDeviceNo
                );
              } catch (error) {
                console.error("Error:", error);
              }
            })();
          } catch (error) {
            console.error("Error:", error);
          }
        }

        // If charts timestamp is greater than 10 mins Call functions
        // Else get the car object(cached) chart data

        if (
          car?.lastUpdated &&
          new Date(car?.lastUpdated).getTime() <
            Date.now() - TEN_MINUTES_IN_SECONDS
        ) {
          invokeGetCountsLambda(
            car?.buildingId,
            car?.elevatingDeviceNo,
            "countRunsDay"
          );
          invokeGetCountsLambda(
            car?.buildingId,
            car?.elevatingDeviceNo,
            "floorsDay"
          );
          invokeGetCountsLambda(
            car?.buildingId,
            car?.elevatingDeviceNo,
            "distanceDay"
          );
          invokinglambda(
            "rawData",
            null,
            car?.buildingId,
            car?.elevatingDeviceNo
          );
        }
        //THIS DOES THE SAME AS ABOVE - BUT WITHOUT THE LAMBDA
        invokinglambda(
          "floorDay",
          null,
          car?.buildingId,
          car?.elevatingDeviceNo
        );
        invokinglambda(
          "floorTripsDay",
          null,
          car?.buildingId,
          car?.elevatingDeviceNo
        );
        setHasRetrievedDailyData(true);
      } else {
        // LEGACY ELEVATOR CALLS NEEDED
        // invokingLegacylambda(
        //   "rawData",
        //   null,
        //   car?.projectId,
        //   car?.elevatingDeviceNo
        // );
        invokingLegacylambda(
          "floorDay",
          null,
          car?.projectId,
          car?.elevatingDeviceNo
        );
        // END OF LEGACY ELEVATOR CALLS NEEDED
        // invokingLegacylambda(
        //   "floorTripsDay",
        //   null,
        //   car?.projectId,
        //   car?.elevatingDeviceNo
        // );
        // invokingLegacylambda(
        //   "door_cycles",
        //   null,
        //   car?.projectId,
        //   car?.elevatingDeviceNo
        // );
        // invokingLegacylambda(
        //   "floor_change",
        //   null,
        //   car?.projectId,
        //   car?.elevatingDeviceNo
        // );
      }
    });
  }, [car]); // Switched this from using car to only on page load - UPDATED:  Gord Bond July 25, 2023

  useEffect(() => {
    // calling function as per the parameter
    if (car.dashboardType !== "legacy") {
      if (displayparameter === "daily") {
        //Eventually make it so it doesn't repeatedly call if it has recently got the data
        if (!hasRetrievedDailyData) {
          datacall(
            "floorDay",
            JSON.parse(decompressedFLoorDayData),
            car?.elevatingDeviceNo
          );
          datacall(
            "floorTripsDay",
            JSON.parse(decompressedTripsDayData),
            car?.elevatingDeviceNo
          );

          if (car?.trips !== undefined) {
            setTrips(car?.trips);
            setDailyTrips(car?.trips);
          } else setTrips(null);
          // setDailyTrips(null)
          if (car?.floors !== undefined) {
            setFloors(parseInt(car?.floors));
            setDailyFloors(car?.floors);
          } else setFloors(null);
          if (car?.distance !== undefined) {
            setDistance(parseInt(car?.distance));
            setDailyDistance(parseInt(car?.distance));
          } else setDistance(null);
          invokeGetCountsLambda(
            car?.buildingId,
            car?.elevatingDeviceNo,
            "countRunsDay"
          );
          invokeGetCountsLambda(
            car?.buildingId,
            car?.elevatingDeviceNo,
            "floorsDay"
          );
          invokeGetCountsLambda(
            car?.buildingId,
            car?.elevatingDeviceNo,
            "distanceDay"
          );

          setHasRetrievedDailyData(true);
        } else {
          // Otherwise use the cached data
          setTrips(car?.trips);
          setFloors(parseInt(car?.floors));
          setDistance(parseInt(car?.distance));
        }
      } else if (displayparameter === "week") {
        if (!hasRetrievedWeeklyData) {
          invokinglambda(
            "floorWeek",
            null,
            car?.buildingId,
            car?.elevatingDeviceNo
          );
          invokinglambda(
            "floorTripsWeek",
            null,
            car?.buildingId,
            car?.elevatingDeviceNo
          );

          if (car?.tripsWeek !== undefined) {
            setTrips(car?.tripsWeek);
            setWeeklyTrips(car?.tripsWeek);
          } else {
            setTrips(null);
            setWeeklyTrips(null);
          }
          if (car?.floorsWeek !== undefined)
            setFloors(parseInt(car?.floorsWeek));
          setWeeklyFloors(car?.floorsWeek);
          if (car?.distanceWeek !== undefined)
            setDistance(parseInt(car?.distanceWeek));
          setWeeklyDistance(car?.distanceWeek);
          invokeGetCountsLambda(
            car?.buildingId,
            car?.elevatingDeviceNo,
            "countRunsWeek"
          );
          invokeGetCountsLambda(
            car?.buildingId,
            car?.elevatingDeviceNo,
            "floorsWeek"
          );
          invokeGetCountsLambda(
            car?.buildingId,
            car?.elevatingDeviceNo,
            "distanceWeek"
          );
          setHasRetrievedWeeklyData(true);
        } else {
          // Otherwise use the cached data
          setTrips(car?.tripsWeek);
          setFloors(parseInt(car?.floorsWeek));
          setDistance(parseInt(car?.distanceWeek));
        }
      }
    } else {
      if (displayparameter === "daily") {
        invokingLegacylambda(
          "rawData",
          null,
          car?.projectId,
          car?.elevatingDeviceNo
        );
        invokingLegacylambda(
          "floorDay",
          null,
          car?.projectId,
          car?.elevatingDeviceNo
        );
      } else if (displayparameter === "week") {
        // Set for loading...
        setVar1Data([]);
        setVar2Data([]);

        setDoorStateTimeStamp([]);
        setDoorStateData([]);

        setXtimeStamp([]);
        setXData([]);

        setYData([]);
        setYtimeStamp([]);

        setZData([]);
        setZtimeStamp([]);
        setrunsperFloor([]);
        // Switch to useQuery
        // invokingLegacylambda(
        //   "rawDataWeek",
        //   null,
        //   car?.projectId,
        //   car?.elevatingDeviceNo
        // );
        // invokingLegacylambda(
        //   "floorWeek",
        //   null,
        //   car?.projectId,
        //   car?.elevatingDeviceNo
        // );
      }
    }
  }, [displayparameter]);

  useEffect(() => {
    if (decompressedRunData !== undefined)
      datacall(
        "floorWeek",
        JSON.parse(decompressedRunData),
        car?.elevatingDeviceNo
      );
  }, [decompressedRunData, car]);

  useEffect(() => {
    if (decompressedTripsWeekData !== undefined)
      datacall(
        "floorTripsWeek",
        JSON.parse(decompressedTripsWeekData),
        car?.elevatingDeviceNo
      );
  }, [decompressedTripsWeekData]);

  useEffect(() => {
    if (decompressedFLoorDayData !== undefined)
      datacall(
        "floorDay",
        JSON.parse(decompressedFLoorDayData),
        car?.elevatingDeviceNo
      );
  }, [decompressedFLoorDayData, car]);

  useEffect(() => {
    if (decompressedTripsDayData !== undefined)
      datacall(
        "floorTripsDay",
        JSON.parse(decompressedTripsDayData),
        car?.elevatingDeviceNo
      );
  }, [decompressedTripsDayData]);

  useEffect(() => {}, [dailyTrips]);

  // Updated to use a hidden link to download the file - Gord Bond July 25, 2023
  const getListOfSignals = () => {
    setSignalLogText("Downloading...");
    setSignalLogClass("secondary-btn-fill");
    Auth.currentCredentials().then((credentials) => {
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: "us-east-1",
      });

      const params = {
        FunctionName: "signalLogsToCsv",
        InvocationType: "RequestResponse",
        Payload: JSON.stringify({ deviceId: car?.elevatingDeviceNo }),
      };

      return lambda.invoke(params, function (err, data) {
        if (err) console.log("ERROR", err, err.stack);
        else {
          const deviceIds = JSON.parse(data.Payload).body.csv_data;
          let csvContent = deviceIds.map((e) => e.join(",")).join("\n");
          let blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
          });
          let link = document.createElement("a");
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "signal_logs.csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setSignalLogText("Get Signal Log");
          setSignalLogClass("secondary-btn-outline");
        }
      });
    });
  };

  function formatDate(inputDate) {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    const dateObject = new Date(inputDate);
    const formattedDate = dateObject
      .toLocaleString("en-US", options)
      .replace(/^(0[1-9]|1[0-2])\//, "$1/")
      .replace(/^0/, "")
      .replace(/, /, ", ");

    return formattedDate;
  }

  return (
    <>
      <div className="main-container">
        <div className="content-container">
          <h3 className="dark-title">
            CAR DETAILS FOR{" "}
            {car?.device_name
              ? (car?.device_name).toUpperCase()
              : car?.elevatingDeviceNo}
          </h3>
          {car.dashboardType !== "legacy" ? (
            <h5 className="dark-title">
              {" "}
              Home / Dashboards / Buildings /{" "}
              {car?.projectLocationofElevatingDevice} /{" "}
              <u>
                {car?.device_name ? car?.device_name : car?.elevatingDeviceNo}
              </u>
            </h5>
          ) : (
            <h5 className="dark-title">
              {" "}
              Home / Dashboards / Buildings /{" "}
              {car?.projectLocationofElevatingDevice}{" "}
              <u>
                {car?.device_name ? car?.device_name : car?.elevatingDeviceNo}
              </u>
            </h5>
          )}
          <div className="flex-container">
            <SmallGeneralCard
              data={{ elevatorType: "Passenger Elevator" }}
              type={"basic-car-info"}
              title={
                car?.device_name ? car?.device_name : car?.elevatingDeviceNo
              }
            />
            {car.dashboardType === "legacy" &&
            floorDataTimeStamp !== undefined ? (
              <SmallGeneralCard
                data={
                  displayparameter === "daily" || displayparameter === ""
                    ? {
                        lastCheckIn:
                          floorDataTimeStamp.length > 0
                            ? formatDate(floorDataTimeStamp[0])
                            : "",
                      }
                    : {
                        lastCheckIn:
                          floorDataTimeStampWeek.length > 0
                            ? formatDate(floorDataTimeStampWeek[0])
                            : "",
                      }
                }
                type={"last-check-in"}
                title={"Last Elevator Movement"}
              />
            ) : (
              <SmallGeneralCard
                data={{
                  lastCheckIn:
                    FloorDataTimeStamp.length > 0
                      ? formatDate(FloorDataTimeStamp[0])
                      : "",
                }}
                type={"last-check-in"}
                title={"Last Elevator Movement"}
              />
            )}
            <StatCard
              data={totalWarnings}
              img={totalWarningsIcon}
              colour={"#fd7e14"}
              title="Warnings"
            />
            <StatCard
              data={totalAlarms}
              img={totalAlarmsIcon}
              colour={"#dc3545"}
              title="Alerts"
            />
          </div>
          <div className="left-aligned-container">
            <button
              className={
                displayparameter === "daily" || displayparameter === ""
                  ? "secondary-btn-fill"
                  : "secondary-btn-outline"
              }
              onClick={(e) => {
                handleClick(e, "daily");
              }}
            >
              Daily Report
            </button>
            <button
              className={
                displayparameter === "week"
                  ? "secondary-btn-fill"
                  : "secondary-btn-outline"
              }
              onClick={(e) => {
                handleClick(e, "week");
              }}
            >
              Weekly Report
            </button>
            {car.dashboardType !== "legacy" && (
              <button className={signalLogClass} onClick={getListOfSignals}>
                {signalLogText}
              </button>
            )}
          </div>
          {car.dashboardType !== "legacy" && (
            <div className="flex-container">
              {displayparameter === "daily" || displayparameter === "" ? (
                <LargerStatCard
                  data={dailyTrips === -1 ? 2 : dailyTrips}
                  title={"Total Number of Runs"}
                />
              ) : displayparameter === "week" ? (
                <LargerStatCard
                  data={weeklyTrips === -1 ? 3 : weeklyTrips}
                  title={"Total Number of Runs"}
                />
              ) : (
                <LargerStatCard data={0} title={"Total Number of Runs"} />
              )}

              {displayparameter === "daily" || displayparameter === "" ? (
                <LargerStatCard
                  data={dailyFloors === -1 ? 2 : dailyFloors}
                  title={"Floors Travelled"}
                />
              ) : displayparameter === "week" ? (
                <LargerStatCard
                  data={weeklyFloors === -1 ? 3 : weeklyFloors}
                  title={"Floors Travelled"}
                />
              ) : (
                <LargerStatCard data={0} title={"Floors Travelled"} />
              )}
              {displayparameter === "daily" || displayparameter === "" ? (
                <LargerStatCard
                  data={dailyDistance === -1 ? 2 : dailyDistance}
                  title={"Distance Travelled by the Elevator [ft]"}
                />
              ) : displayparameter === "week" ? (
                <LargerStatCard
                  data={weeklyDistance === -1 ? 3 : weeklyDistance}
                  title={"Distance Travelled by the Elevator [ft]"}
                />
              ) : (
                <LargerStatCard
                  data={0}
                  title={"Distance Travelled by the Elevator [ft]"}
                />
              )}
              <LargerStatCard
                data={"96%"}
                title={"Uptime (30 days)"}
                placeholder={true}
              />
              <LargerStatCard
                data={"98%"}
                title={"Uptime (365 days)"}
                placeholder={true}
              />
              <LargerStatCard
                data={1}
                title={"Callbacks (30 days)"}
                placeholder={true}
              />
              <LargerStatCard
                data={7}
                title={"Callbacks (365 days)"}
                placeholder={true}
              />
            </div>
          )}
          <div className="flex-container">
            {car.elevatingDeviceNo !== "EID_0003" &&
            car.dashboardType !== "legacy" ? (
              <>
                {displayparameter === "daily" || displayparameter === "" ? (
                  <>
                    <ChartCard
                      data={{
                        x: FloorDataTimeStamp,
                        y: FloorData,
                        currentData: noCurrentData,
                      }}
                      lookUpFloorList={lookUpFloors}
                      type={"line"}
                      title={"Elevator Movement (Last 5 minutes)"}
                    />
                    <ChartCard
                      data={{ data: runsperFloor, currentData: noCurrentData }}
                      type={"bar"}
                      lookUpFloorList={lookUpFloors}
                      title={"Number of Runs per Floor"}
                    />
                    <ChartCard
                      data={{ data: heatdata, currentData: noCurrentData }}
                      lookUpFloorList={lookUpFloors}
                      type={"heatmap"}
                      title={"Elevator Trips (From Floor - To Floor)"}
                    />
                  </>
                ) : (
                  <>
                    <ChartCard
                      data={{ x: FloorDataTimeStamp, y: FloorData }}
                      lookUpFloorList={lookUpFloors}
                      type={"line"}
                      title={"Elevator Movement (Last 5 minutes)"}
                    />
                    <ChartCard
                      data={{ data: runsperFloorWeek }}
                      lookUpFloorList={lookUpFloors}
                      type={"bar"}
                      title={"Number of Runs per Floor"}
                    />
                    <ChartCard
                      data={{ data: heatdataWeek }}
                      lookUpFloorList={lookUpFloors}
                      type={"heatmap"}
                      title={"Elevator Trips (From Floor - To Floor)"}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {/* {!legacyRawDataLoading &&
                var2Data !== undefined &&
                var1Data !== undefined ? (
                  <ChartCard
                    data={
                      displayparameter === "daily" || displayparameter === ""
                        ? { x: var2Data, y: var1Data }
                        : { x: var2DataWeek, y: var1DataWeek }
                    }
                    type={"line"}
                    title={"Var-1 Vs Time"}
                    isLegacy={true}
                  />
                ) : (
                  <ChartCard
                    data={{ x: [], y: [] }}
                    type={"line"}
                    title={"Var-1 Vs Time"}
                    isLegacy={true}
                  />
                )} */}
                {!legacyRawDataLoading &&
                xTimeStamp !== undefined &&
                xData !== undefined ? (
                  <ChartCard
                    data={
                      displayparameter === "daily" || displayparameter === ""
                        ? { x: xTimeStamp, y: xData }
                        : { x: xTimeStampWeek, y: xDataWeek }
                    }
                    type={"line"}
                    title={"Vertical (X)"}
                    isLegacy={true}
                  />
                ) : (
                  <ChartCard
                    data={{ x: [], y: [] }}
                    type={"line"}
                    title={"Vertical (X)"}
                    isLegacy={true}
                  />
                )}
                {!legacyRawDataLoading &&
                yTimeStamp !== undefined &&
                yData !== undefined ? (
                  <ChartCard
                    data={
                      displayparameter === "daily" || displayparameter === ""
                        ? { x: yTimeStamp, y: yData }
                        : { x: yTimeStampWeek, y: yDataWeek }
                    }
                    type={"line"}
                    title={"Horizontal (Y)"}
                    isLegacy={true}
                  />
                ) : (
                  <ChartCard
                    data={{ x: [], y: [] }}
                    type={"line"}
                    title={"Horizontal (Y)"}
                    isLegacy={true}
                  />
                )}
                {!legacyRawDataLoading &&
                zTimeStamp !== undefined &&
                zData !== undefined ? (
                  <ChartCard
                    data={
                      displayparameter === "daily" || displayparameter === ""
                        ? { x: zTimeStamp, y: zData }
                        : { x: zTimeStampWeek, y: zDataWeek }
                    }
                    type={"line"}
                    title={"Horizontal (Z)"}
                    isLegacy={true}
                  />
                ) : (
                  <ChartCard
                    data={{ x: [], y: [] }}
                    type={"line"}
                    title={"Horizontal (Z)"}
                    isLegacy={true}
                  />
                )}
                {!legacyRawDataLoading &&
                doorStateTimeStamp !== undefined &&
                doorStateData !== undefined ? (
                  <ChartCard
                    data={
                      displayparameter === "daily" || displayparameter === ""
                        ? { x: doorStateTimeStamp, y: doorStateData }
                        : { x: doorStateTimeStampWeek, y: doorStateDataWeek }
                    }
                    type={"line"}
                    title={"Doorstate Vs Time"}
                    isLegacy={true}
                  />
                ) : (
                  <ChartCard
                    data={{ x: [], y: [] }}
                    type={"line"}
                    title={"Doorstate Vs Time"}
                    isLegacy={true}
                  />
                )}
                {!isRunsPerFloorLoading &&
                runsPerFloor !== null &&
                runsPerFloor !== undefined ? (
                  <ChartCard
                    data={{ data: runsPerFloor }}
                    type={"bar"}
                    title={"Number of Runs per Floor"}
                  />
                ) : (
                  <ChartCard
                    data={{ data: [] }}
                    type={"bar"}
                    title={"Number of Runs per Floor"}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CarDetails;
