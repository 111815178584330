import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NotFound } from "./pages/NotFound.js";
import Footer from "./components/Footer";
import BuildingSummary from "./pages/BuildingSummary";
import BuildingDetails from "./pages/BuildingDetails";
import CarDetails from "./pages/CarDetails";
import ChartJSTesting from "./pages/ChartJSTesting";
import CurrentIotProjects from "./pages/CurrentIotProjects";
import CreateAndAssignProject from "./pages/CreateAndAssignProject";
import ProjectInformation from "./pages/ProjectInformation";
import CalibrationOfVariables from "./pages/CalibrationOfVariables";
import GuestAssignment from "./pages/GuestAssignment";
import ProjectsDetails from "./pages/ProjectsDetails";
import Admin from "./pages/Admin";
import {
  Authenticator,
  useAuthenticator,
  useTheme,
  View,
  Image,
  Text,
  Heading,
  Button,
  Flex,
  CheckboxField,
} from "@aws-amplify/ui-react";
import Navbar from "./components/Navbar";
import SideNav from "./components/SideNav";
import "./App.css";
import "./styles.css";
import { FaBars } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import Amplify, { Auth } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";

import "./fonts/Mulish-Bold.ttf";
import "./fonts/OpenSans-Regular.ttf";
import "./fonts/OpenSans-SemiBold.ttf";

import awsExports from "./aws-exports";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

const persister = createSyncStoragePersister({
  storage: window.sessionStorage,
});

Amplify.configure(awsExports);

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Neuvativ logo"
          src="https://public-assets-tsaro.s3.amazonaws.com/Insights-Wht-logo-LRG.png"
          width={"185px"}
          marginTop="50px"
          marginBottom="50px"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <div className="logo-footer-login">
          <div className="logos-2">
            <a href="https://tsarosolutions.com" className="website-links">
              <img
                src="https://public-assets-tsaro.s3.amazonaws.com/Tsaro+Logo+White+(1).png"
                alt="Tsaro"
              />
            </a>
          </div>
          <div className="logos-2">
            <a href="https://mosaicone.com" className="website-links">
              <img
                src="https://public-assets-tsaro.s3.amazonaws.com/MosaicONE-+by+MAD-white.png"
                alt="Mad Elevators"
              />
            </a>
          </div>
        </div>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={5}
        >
          {/* Sign in to your Tsaro account */}
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
            color="white"
          >
            Reset Password
          </Button>
          {/* <div className='logo-footer-login'>
            <div className="logos-2">
                    <img src="https://public-assets-tsaro.s3.amazonaws.com/Tsaro+Logo+White+(1).png" alt="Tsaro"/>
                </div>
                <div className="logos-2">
                    <img src="https://public-assets-tsaro.s3.amazonaws.com/MosaicONE-+by+MAD-white.png" alt="Mad Elevators"/>
                </div>
          </div> */}
        </View>
      );
    },
  },
  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={5}
        >
          {/* Create a new Tsaro account */}
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            color="#fff"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          {/* Enter Information: */}
        </Heading>
      );
    },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          {/* Enter Information: */}
        </Heading>
      );
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          {/* Enter Information: */}
        </Heading>
      );
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          {/* Enter Information: */}
        </Heading>
      );
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          {/* Enter Information: */}
        </Heading>
      );
    },
  },
};

const formFields = {
  signIn: {
    username: {
      labelHidden: true,
      placeholder: "E-mail",
    },
  },
  signUp: {
    email: {
      labelHidden: true,
      label: "E-mail",
      placeholder: "E-mail",
      order: 1,
    },
    password: {
      labelHidden: true,
      label: "Password:",
      placeholder: "Password",
      isRequired: false,
      order: 2,
    },
    confirm_password: {
      labelHidden: true,
      label: "Confirm Password",
      order: 3,
    },
    preferred_username: {
      labelHidden: true,
      label: "Preferred Username",
      isRequired: true,
      order: 4,
    },
    zoneinfo: {
      labelHidden: true,
      label: "Zone info",
      placeholder: "Enter your Unique Code",
      isRequired: true,
      order: 5,
    },
  },
  forceNewPassword: {
    password: {
      labelHidden: true,
      placeholder: "Password",
    },
  },
  resetPassword: {
    username: {
      labelHidden: true,
      placeholder: "Email",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: true,
      placeholder: "Enter your Confirmation Code",
      label: "Confirmation Code:",
      isRequired: false,
    },
    confirm_password: {
      labelHidden: true,
      placeholder: "Confirm Password",
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: "test issuer",
      totpUsername: "amplify_qr_test_user",
    },
    confirmation_code: {
      labelHidden: true,
      label: "New Label",
      placeholder: "Enter your Confirmation Code",
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: true,
      label: "New Label",
      placeholder: "Enter your Confirmation Code",
      isRequired: false,
    },
  },
};

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      },
    },
  });

  //Class for the nav - whether it is hidden or not
  const [navClass, setNavClass] = useState("nav-hide");
  const [sideNav, setSideNav] = useState("nav-side");
  //Whether menu icon is visible or not
  const [menuIconVisible, setMenuIconVisible] = useState(true);
  //Whether close icon is visible or not
  const [closeIconVisible, setCloseIconVisible] = useState(false);

  const { tokens } = useTheme();

  //Handles open/close of hamburger icon
  const handleClick = () => {
    if (window.innerWidth < 1571) {
      console.log("clicking...", window.innerWidth);
      setNavClass((v) => (v === "nav-hide" ? "nav-show" : "nav-hide"));
      setMenuIconVisible((v) => !v);
      setCloseIconVisible((v) => !v);
    }
  };

  const [userGroup, setUserGroup] = useState();

  const fetchUserGroup = () => {
    try {
      const userGroupArray =
        Auth?.user?.signInUserSession?.idToken?.payload["cognito:groups"];
      setUserGroup(userGroupArray[0]);
      console.log("USER: ", Auth?.user);
      console.log("userGroup: ", userGroup);
    } catch (e) {
      console.log("Error: ", e);
      console.log("User Group with error: ", userGroup);
    }
  };
  const logout = () => {
    Auth.signOut().then(() => {
      window.location.href = `${window.location.origin}/`;
    });
  };
  //Styles for background hero image
  const bgViewStyles = {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    boxShadow: "none",
    height: "100vh",
    overflowY: "hidden",
  };
  // tokens.components.button.

  const barViewStyles = {
    backgroundImage:
      "linear-gradient(rgb(253 126 20 / 80%), rgb(253 126 20 / 80%), rgb(253 126 20 / 80%), rgb(220 53 69 / 80%))",
    height: "100vh",
    width: "var(--amplify-space-relative-full)",
    maxWidth: "30rem",
    alignItems: "center",
    margin: "auto",
    boxShadow: "none",
    overflowY: "auto",
  };
  const loggedInStyles = {};

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      {/* <QueryClientProvider client={queryClient}> */}
      <View
        backgroundImage={
          userGroup === undefined
            ? "url(https://public-assets-tsaro.s3.amazonaws.com/insights-login-img.png)"
            : ""
        } //This real one needs to be place in an S3 bucket
        style={userGroup === undefined ? bgViewStyles : loggedInStyles}
      >
        <View style={userGroup === undefined ? barViewStyles : loggedInStyles}>
          {/* <View style = {barViewStyles}> */}
          <Authenticator formFields={formFields} components={components}>
            {/* <Authenticator formFields={formFields} components={components} style={userGroup !== undefined ? bgViewStyles : loggedInStyles}> */}

            {({ signOut, user }) => (
              <Router
                forceRefresh={true}
                getUserConfirmation={fetchUserGroup()}
              >
                <div className="App">
                  <div className="log-btns">
                    <div className="logo-top-container">
                      <div className="logo-container-sm">
                        <img
                          src="https://public-assets-tsaro.s3.amazonaws.com/Insights-Wht-logo-LRG.png"
                          alt="logo"
                          className="logo-img-w"
                        />
                      </div>
                    </div>
                    <div className="signout-group-container">
                      <div className="signout-group">
                        <h3 className="userName">
                          Hello, {user?.attributes?.email}
                        </h3>
                        <button
                          className="button-is-primary main-logout"
                          onClick={logout}
                        >
                          Sign out
                        </button>
                        <FaBars
                          className={
                            menuIconVisible ? "menuIcon show" : "menuIcon hide"
                          }
                          onClick={handleClick}
                        />
                        <GrClose
                          className={
                            closeIconVisible
                              ? "closeIcon show"
                              : "closeIcon hide"
                          }
                          onClick={handleClick}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <Navbar navClass={navClass} setNavClass={setNavClass} logout={logout} handleClick={handleClick}/> */}
                  <SideNav
                    navClass={sideNav}
                    setNavClass={setSideNav}
                    logout={logout}
                    handleClick={handleClick}
                  />
                  <>
                    <Routes>
                      {/* <Route path="/" element={<MainPage />} /> Removed for now. */}
                      <Route
                        path="/current-projects"
                        element={<CurrentIotProjects />}
                      />
                      <Route path="/" element={<BuildingSummary />} />
                      <Route
                        path="/building-details"
                        element={<BuildingDetails />}
                      />
                      <Route path="/car-details" element={<CarDetails />} />
                      <Route
                        path="/chartjstesting"
                        element={<ChartJSTesting />}
                      />
                      <Route
                        path="/iot-devices-current-iot-projects/projects-details"
                        element={<ProjectsDetails />}
                      />
                      {userGroup === "Admin" && (
                        <>
                          <Route
                            path="/iot-devices-create-and-assign-project"
                            element={<CreateAndAssignProject />}
                          />
                        </>
                      )}
                      {(userGroup === "Admin" ||
                        userGroup === "ElevatorCompany" ||
                        userGroup === "BuildingManager") && (
                        <>
                          <Route
                            path="/iot-devices-project-information"
                            element={<ProjectInformation />}
                          />
                          <Route
                            path="/iot-devices-calibration-of-variables"
                            element={<CalibrationOfVariables />}
                          />
                          <Route
                            path="/iot-devices-guest-assignment"
                            element={<GuestAssignment />}
                          />
                          <Route path="/admin" element={<Admin />} />
                        </>
                      )}
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </>
                </div>

                {/* <Footer /> */}
              </Router>
            )}
          </Authenticator>
        </View>
      </View>
    </PersistQueryClientProvider>
  );
}
