import React, {
    useState,
    useEffect
  } from 'react';
import { useNavigate } from "react-router-dom";

// Equipment information is being passed from the landing page
function StatCard({data, img, colour, title}){

    // const [statusClass, setStatusClass] = useState('equipment-status-working')
    
    

    const navigate = useNavigate();
    const handleViewDetails = (e, data) => {
        console.log("Clicking on: ", data)
        // navigate('/BuildingDetails', {
        //   state: JSON.stringify(data)
        // });
      }
    
      useEffect(() => {
        console.log("Stat: ", data)
        // buildingData.equipment_status === 'working' ? setStatusClass('equipment-status-working') : setStatusClass('equipment-status-fail')
      },[data])


    return (
        <div className="stat-card" onClick={(e)=>{handleViewDetails(e, data)}}>
            <div className="stat-card-container">
                <div className='statcard-icon-container'>
                    <img className='statcard-icon' src={img} alt="icon"/>
                </div>
                <div className='stat-card-info'>
                    <h5 className='stat-card-title'>{title}</h5>
                    <div className='stat-text' style={{color: colour}}>{data}</div>
                </div>


                
            </div>
        </div>
        );
}

export default StatCard;