import React, { useEffect, useState, useRef, useMemo } from "react";
import { Line, Bar, Chart } from "react-chartjs-2";
import "date-fns";
// Import for the labels of heatmap
import { AxisBottom, AxisLeft } from "@visx/axis";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
  TimeScale,
  Decimation,
} from "chart.js";

// Imports for heatmap
import { HeatmapRect } from "@visx/heatmap";

import { scaleLinear, scaleBand } from "@visx/scale";
import "chartjs-adapter-date-fns";

// Import for the zoom-plugins of chart
import zoomPlugin from "chartjs-plugin-zoom";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  zoomPlugin,
  TimeScale,
  Decimation
);
/**
 * Card component to be used for containing custom charts
 * Designed with the Car Details page in mind
 * @param {*} data - This is the data for the content of the card/chart
 * @param {*} title - Title of the chart
 * @param {*} type - Type of chart being displayed so that you can select different renderings
 * @returns
 * Written by Gord Bond  - July 5, 2023
 */

function SmallGeneralCard({
  data,
  type,
  title,
  lookUpFloorList,
  isLegacy = false,
}) {
  if (type === "heatmap") {
    console.log("DATA IN CHART CARD: ", data);
  }
  const [xAxisData, setXAxisData] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);
  const [carData, setCarData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [firstTimestamp, setfirstTimestamp] = useState();
  const [lastTimestamp, setLastTimestamp] = useState();
  const [floors, setFloors] = useState();
  const [minValueGraph, setMinValueGraph] = useState(0);
  const [noCurrentData, setNoCurrentData] = useState(false);
  const [floorList, setFloorList] = useState(lookUpFloorList?.floors);
  const zoomOptions = {
    zoom: {
      wheel: {
        enabled: true,
      },

      mode: "x",
      drag: {
        enabled: true,
        borderColor: "rgb(54, 162, 235)",
        borderWidth: 1,
        backgroundColor: "rgba(54, 162, 235, 0.3)",
      },
    },
    pan: {
      modifierKey: "ctrl",
      enabled: true,
      mode: "x",
    },
  };
  let yAxisLabel = "";
  if (isLegacy) {
    switch (title) {
      case "Var-1 Vs Time":
        yAxisLabel = "Var-1";
        break;
      case "Acceleration on X-axis Vs Time":
        yAxisLabel = "Acceleration (g)";
        break;
      case "Acceleration on Y-axis Vs Time":
        yAxisLabel = "Acceleration (g)";
        break;
      case "Acceleration on Z-axis Vs Time":
        yAxisLabel = "Acceleration (g)";
        break;
      case "Doorstate Vs Time":
        yAxisLabel = "Doorstate";
        break;
      default:
        break;
    }
  } else {
    yAxisLabel = "Floor";
  }

  // console.log("yAxisData: ", yAxisData)
  const lineData = {
    labels: xAxisData,
    datasets: [
      {
        label: yAxisLabel,
        data: yAxisData,
        borderColor: "#8c92ac",
        tension: 0.1,
      },
    ],
  };

  const legacyLineData = {
    labels: xAxisData,
    datasets: [
      {
        label: yAxisLabel,
        data: yAxisData,
        borderColor: "#8c92ac",
        tension: 0.1,
        pointRadius: 0,
      },
    ],
  };

  // let firstTimestamp;
  const events = true;
  const handleViewDetails = (e, data) => {
    console.log("Clicking on: ", data);
  };

  useEffect(() => {
    if (!(data === null || data === undefined || data.length === 0)) {
      if (type === "line") {
        // Check if the date string matches the format "MM/DD/YYYY, HH:mm"
        // data.x = data?.x.slice(0, 999);
        let dateArray = [];
        dateArray = data?.x;
        setXAxisData(dateArray);
        setfirstTimestamp(dateArray[0]);
        setLastTimestamp(dateArray.length - 1);
        setYAxisData(data?.y);
        setFloors(Math.max(...data?.y));
        setMinValueGraph(Math.min(...data?.y));
        setNoCurrentData(data?.currentData);
        // setLookUpFloors(data?.lookUpFloors)
        // Loading unless data available for each property - UPDATED: Gord Bond, July 25th, 2023
        if (data?.y.length > 0 && dateArray.length > 0) {
          setisLoading(false);
        } else {
          setisLoading(true);
        }
      } else if (type === "bar" || type === "heatmap") {
        setCarData(data?.data);
        setNoCurrentData(data?.currentData);
        // Loading unless array has data - UPDATED: Gord Bond, July 25th, 2023
        if (type === "heatmap") {
          console.log("DEBUGGING HEAT MAP: ", data?.data);
          if (data?.data.length > 0) setisLoading(false);
          else {
            setisLoading(true);
          }
        } else {
          console.log("DEBUGGING BAR: ", data?.data);
          // Loading unless there are keys in the object - UPDATED: Gord Bond, July 25th, 2023
          if (Object.keys(data?.data).length > 0) setisLoading(false);
          else {
            setisLoading(true);
          }
        }

        if (type === "heatmap" && data?.data.length !== 0) {
          setFloors(data?.data.length);
        }
      }
    }

    // buildingData.equipment_status === 'working' ? setStatusClass('equipment-status-working') : setStatusClass('equipment-status-fail')
  }, [data]);

  useEffect(() => {
    if (lookUpFloorList?.floors !== undefined) {
      setFloorList(lookUpFloorList?.floors);
      console.log("LOOKUP FLOOR LIST: ", lookUpFloorList?.floors);
    }
    console.log("LOOKUP FLOOR LIST: ", lookUpFloorList?.floors);
  }, [lookUpFloorList]);

  const handleZoomReset = () => {
    chartRef.current.resetZoom(); // Assuming you have a reference to the chart instance
  };

  const minDate = new Date(firstTimestamp);

  // Subtract 5 minutes (300000 milliseconds) from the first timestamp to get the maximum date
  let maxDate = new Date(new Date(firstTimestamp).getTime() - 5 * 60 * 1000);
  if (isLegacy) {
    maxDate = new Date(new Date(firstTimestamp).getTime() - 5 * 60 * 60 * 1000);
  }

  // getting the bins.bin value for the HEATMAP.
  let countValues = [];
  if (type === "heatmap") {
    countValues =
      carData !== null && carData !== undefined
        ? carData.flatMap(({ bins }) => bins.map(({ count }) => count))
        : [0, 0];
  }
  console.log("COUNT VALUES FOR HEATMAP: ", countValues);

  //caculating min and max value of the countvalues
  const minValue = Math.min(...countValues);
  const maxValue = Math.max(...countValues);
  console.log("MIN AND MAX VALUES FROM COUNT VALUES: ", minValue, maxValue);

  // defining colorscale for HEATMAP with the range of minValue to maxValue.
  const colorScale = scaleLinear({
    range: ["#000000", "#b48e92"],
    domain: [minValue, maxValue],
  });

  // defining opacityscale for HEATMAP
  const opacityScale = scaleLinear({
    range: [0.1, 1],
    domain: [minValue, maxValue],
  });

  const chartRef = useRef(null);

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      zoom: zoomOptions,
    },
    scales: {
      x: {
        type: "time",
        time: {
          tooltipFormat: "MMM dd yyyy HH:mm", // Format for the tooltip when hovering over data points
          displayFormats: {
            minute: "MMM dd yyyy HH:mm", // Format for the axis labels displaying date and time (adjust as needed)
          },
          unit: "minute", // Use 'minute' as the time unit to display labels with Cartesian time pattern
        },
        title: {
          display: true,
          text: "Timestamp",
          font: {
            weight: "bold",
            size: 14,
          },
        },
        ticks: {
          maxRotation: 90,
          minRotation: 90,
          autoSkip: true, // Enable auto skipping of labels to create a pattern
          font: {
            weight: "bold",
            size: 10,
          },
        },
        min: minDate,
        max: maxDate,
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: "Floor",
          font: {
            weight: "bold",
            size: 8,
          },
        },
        // Use this to change the display value of the y-axis labels
        ticks: {
          callback: function (value, index, ticks) {
            // const lookUpArray = [{"floor":"1","index":1},{"floor":"2","index":2},{"floor":"3","index":3},{"floor":"4","index":4},{"floor":"5","index":5},{"floor":"6","index":6},{"floor":"7","index":8},{"floor":"9","index":9},{"floor":"10","index":10},{"floor":"11","index":11},{"floor":"PH","index":12}]
            const lookUpArray = floorList;
            const indexOfFloor = lookUpArray.findIndex(
              (item) => item.index === value
            );
            try {
              return lookUpArray[indexOfFloor].floor;
            } catch (err) {
              return value;
            }
          },
        },
        suggestedMin: 0, // Set the minimum value of the y-axis
        suggestedMax: floors,
      },
    },
  };

  const decimation = {
    enabled: false,
    algorithm: "min-max",
  };

  const lineOptionsLegacy = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      zoom: zoomOptions,
      decimation: decimation,
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          tooltipFormat: "MMM dd yyyy HH:mm", // Format for the tooltip when hovering over data points
          displayFormats: {
            minute: "MMM dd yyyy HH:mm", // Format for the axis labels displaying date and time (adjust as needed)
          },
          unit: "minute", // Use 'minute' as the time unit to display labels with Cartesian time pattern
        },
        title: {
          display: true,
          text: "Timestamp",
          font: {
            weight: "bold",
            size: 14,
          },
        },
        ticks: {
          maxRotation: 90,
          minRotation: 90,
          autoSkip: true, // Enable auto skipping of labels to create a pattern
          font: {
            weight: "bold",
            size: 10,
          },
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: yAxisLabel,
          font: {
            weight: "bold",
            size: 14,
          },
        },
        ticks: {
          // maxRotation: 90,
          // minRotation: 90,
          autoSkip: true, // Enable auto skipping of labels to create a pattern
          font: {
            weight: "bold",
            size: 10,
          },
        },

        suggestedMin: minValueGraph, // Set the minimum value of the y-axis
        suggestedMax: floors,
      },
    },
  };

  const barData = useMemo(
    () => ({
      labels:
        data?.data !== null && data?.data !== undefined
          ? Object.keys(data?.data)
          : null,
      datasets: [
        {
          label: "Runs",
          data:
            data?.data !== null && data?.data !== undefined
              ? Object.values(data?.data)
              : null,

          backgroundColor: "#d8bcab",
        },
      ],
    }),
    [data]
  );

  const barDataLegacy = useMemo(
    () => ({
      labels:
        data?.data !== null && data?.data !== undefined
          ? Object.keys(data?.data)
          : null,
      datasets: [
        {
          label: "Runs",
          data:
            data?.data !== null && data?.data !== undefined
              ? Object.values(data?.data)
              : null,

          backgroundColor: "#d8bcab",
        },
      ],
    }),
    [data?.data]
  );

  if (type === "heatmap") {
  }

  const barOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      zoom: zoomOptions,
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Floor",
        },
        ticks: {
          callback: function (value, index, ticks) {
            const indexOfFloor = floorList.findIndex((item) => {
              return item.index === parseInt(this.getLabelForValue(value));
            });

            try {
              return floorList[indexOfFloor].floor;
            } catch (err) {
              console.log("IN THE CATCH: ", err);
              return value;
            }
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 10,
        },
        title: {
          display: true,
          text: "Runs",
        },
      },
    },
  };

  const barOptionsLegacy = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        zoom: zoomOptions,
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Floor",
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 10,
          },
          title: {
            display: true,
            text: "Runs",
          },
        },
      },
    }),
    [zoomOptions]
  );

  // useEffect(() => {
  //   console.log("barDataLegacy: ", barDataLegacy);
  // }, [barDataLegacy]);
  // useEffect(() => {
  //   console.log("barOptionsLegacy: ", barOptionsLegacy);
  // }, [barOptionsLegacy]);
  // useEffect(() => {
  //   console.log("barData: ", barData);
  // }, [barData]);
  // useEffect(() => {
  //   console.log("barOptions: ", barOptions);
  // }, [barOptions]);

  return (
    <div
      className="chart-card"
      onClick={(e) => {
        handleViewDetails(e, data);
      }}
    >
      <div className="chart-card-container">
        <div className="chart-card-info">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 className="chart-card-title">{title}</h3>
            {type === "heatmap" ? (
              <></>
            ) : (
              <button className="zoomReset" onClick={handleZoomReset}>
                Reset Zoom
              </button>
            )}
          </div>

          {!isLoading ? (
            <>
              {type === "line" ? (
                <>
                  {isLegacy === false && floorList !== undefined ? (
                    <Chart
                      type="line"
                      data={lineData}
                      options={lineOptions}
                      ref={chartRef}
                    />
                  ) : (
                    <Chart
                      type="line"
                      data={legacyLineData}
                      options={lineOptionsLegacy}
                      ref={chartRef}
                    />
                  )}
                </>
              ) : type === "bar" ? (
                <>
                  {isLegacy === false && floorList !== undefined ? (
                    <Bar data={barData} options={barOptions} ref={chartRef} />
                  ) : (
                    <Bar
                      data={barDataLegacy}
                      options={barOptionsLegacy}
                      ref={chartRef}
                    />
                  )}
                </>
              ) : type === "heatmap" && floorList !== undefined ? (
                <>
                  <svg height="370px" width="620px" id="heatmapSVG">
                    <text
                      x={0}
                      y={-10}
                      transform="rotate(-90)"
                      fontSize={12}
                      textAnchor="middle"
                    >
                      Floor
                    </text>

                    <AxisBottom
                      scale={scaleBand({
                        domain: Object.values(data?.data).map((bin) => {
                          const lookUpArray = floorList;
                          const indexOfFloor = lookUpArray.findIndex(
                            (item) =>
                              item.index === parseInt(bin?.bin.toString())
                          );
                          try {
                            console.log(
                              "HEAT MAP FLOOR: ",
                              lookUpArray[indexOfFloor].floor
                            );
                            return lookUpArray[indexOfFloor].floor;
                          } catch (err) {
                            console.log("HEAT MAP ERROR: ", err);
                            return bin?.bin.toString();
                          }
                        }),
                        range: [15, 580],
                      })}
                      top={335}
                      tickLength={15}
                      tickFormat={(value) => `${value}`}
                      strokeWidth={0}
                      numTicks={30}
                      tickLabelProps={() => ({
                        fontSize: 8,
                        textAnchor: "middle",
                        fontWeight: "bold",
                      })}
                    />

                    {/* Add Y-axis labels */}
                    <AxisLeft
                      scale={scaleBand({
                        domain: Object.values(data?.data).map((bin) => {
                          const lookUpArray = floorList;
                          const indexOfFloor = lookUpArray.findIndex(
                            (item) =>
                              item.index === parseInt(bin?.bin.toString())
                          );
                          try {
                            return lookUpArray[indexOfFloor].floor;
                          } catch (err) {
                            return bin?.bin.toString();
                          }
                        }),
                        range: [357, 2],
                      })}
                      left={25}
                      tickLength={15}
                      tickFormat={(value) => `${value}`}
                      strokeWidth={0}
                      numTicks={30}
                      tickLabelProps={() => ({
                        fontSize: 8,
                        textAnchor: "middle",
                        dx: "-0.5em",
                        fontWeight: "bold",
                      })}
                    />

                    <HeatmapRect
                      data={carData}
                      xScale={scaleLinear({
                        range: [
                          18,
                          floors > 12 ? 565 / floors + 18 : 540 / floors + 18,
                        ],
                      })}
                      yScale={scaleLinear({
                        range: [0, 350 / floors],
                      })}
                      colorScale={colorScale}
                      opacityScale={opacityScale}
                      margin={{ top: 10, bottom: 30, left: 30, right: 30 }}
                      // Set the size of each rectangle in the heatmap
                      events={true}
                      gap={1}
                    >
                      {(heatmap) =>
                        heatmap.map((heatmapBins) =>
                          heatmapBins.map((bin) => {
                            return (
                              <rect
                                key={`heatmap-rect-${bin.row}-${bin.column}`}
                                className="visx-heatmap-rect"
                                width={555 / floors - 6}
                                height={340 / floors - 4}
                                x={bin.x}
                                y={bin.y}
                                fill={bin.color}
                                fillOpacity={bin.opacity}
                                onClick={() => {
                                  if (!events) return;
                                  const { row, column } = bin;
                                  alert(
                                    JSON.stringify({
                                      row,
                                      column,
                                      bin: bin.bin,
                                    })
                                  );
                                }}
                              />
                            );
                          })
                        )
                      }
                    </HeatmapRect>
                  </svg>
                </>
              ) : (
                <></>
              )}
            </>
          ) : noCurrentData === true ? (
            <div className="loader-container">
              <h3 className="currentData">
                There is no current data available for this elevator.
              </h3>
            </div>
          ) : (
            <div className="loader-container">
              {/* See classes in App.css for source */}
              <div className="spinner"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SmallGeneralCard;
